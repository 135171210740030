'use client';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { observer } from 'mobx-react-lite';
import { usePathname } from 'next/navigation';
import { Suspense, useCallback, useState } from 'react';
import { useGlobalContext } from '../../../hooks/useGlobalContext';
import { CommonErrorBoundary } from '../../errors/boundaries/CommonErrorBoundary';
import CartButton from '../../navigation/Header/components/CartButton';
import { HoverIntent } from '../../utility/HoverIntent';
import { MiniCart } from '../MiniCart';

import S from './styles.module.scss';

/**
 * Mini Cart Button used to control the Mini Cart drop down in the header.
 */
export const MiniCartButton = observer(function MiniCartButton() {
  const [openMiniCart, setOpenMiniCart] = useState(false);
  const { cart } = useGlobalContext();

  const handleOpenCart = useCallback(() => setOpenMiniCart(true), []);
  const handleCloseCart = useCallback(() => setOpenMiniCart(false), []);

  const shouldOpenCart = openMiniCart && cart.totalProductQuantity > 0;

  // Check to see if the current page is the cart page.
  /* eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion -- Next complains that this
  hook may return null for fallback routes, which are deprecated and unused. */
  const pathname = usePathname()!;
  const isCartPage = pathname.endsWith('/cart');

  return (
    <HoverIntent
      onHoverIn={handleOpenCart}
      onHoverOut={handleCloseCart}
      disabled={isCartPage}
    >
      <div className={S.container}>
        <Suspense>
          <CartButton count={cart.totalProductQuantity} />
        </Suspense>
        <div
          className={classes(S.miniCart, shouldOpenCart ? S.visible : S.hidden)}
          aria-hidden={!shouldOpenCart}
          // This uses the `inert` HTML attribute to disable all interactive
          // elements within the minicart. This will prevent the contents from
          // being focused too.
          //
          // See
          // https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/inert
          //
          // Q: Why use a ref instead of just passing `inert` as a prop?
          //
          // A: React doesn't yet accept `inert` as a standard property it
          // seems. So it refuses to set `inert` when passed as a prop. To go
          // around this, we can directly set it on the node.
          //
          // See: https://github.com/WICG/inert/issues/58
          //
          // Q: If `inert` is considered non-standard by React, does it mean
          // that it isn't widely supported yet?
          //
          // A: That used to be the case until recently. CanIUse now reports a
          // global support of 94.66%.
          //
          // See https://caniuse.com/mdn-api_htmlelement_inert
          //
          // Support for `inert` was introduced to React 19. Remove this
          // workaround after updating.
          //
          // See: https://github.com/facebook/react/issues/17157
          ref={(node) =>
            node &&
            // If this tooltip is interactive...
            (shouldOpenCart
              ? // Remove the inert attribute.
                node.removeAttribute('inert')
              : // Else, include it.
                node.setAttribute('inert', ''))
          }
        >
          <Suspense>
            <CommonErrorBoundary fallback={null}>
              {/* Always render the MiniCart component, even if the popup
              is closed. This is to prevent the internal PayPal button from
              unmounting and aborting a payment request. */}
              <MiniCart cart={cart} />
            </CommonErrorBoundary>
          </Suspense>
        </div>
      </div>
    </HoverIntent>
  );
});
