'use client';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { observer } from 'mobx-react-lite';
import { ReactNode, useRef } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import type IStylable from '../../../../traits/IStylable';
import { Curtain } from '../../../Curtain';
import useConditionallyDisableScrolling from '../../../hooks/useConditionallyDisableScrolling';

import S from './styles.base.module.scss';

export interface IModalProps extends IStylable {
  /** Called when the close button is clicked. */
  onClose: () => void;

  /** Called when the close animation is finished. */
  onCloseFinished?: () => void;

  /** Whether the component is currently open. */
  isOpen: boolean;

  /** The contents of the modal. */
  children: ReactNode;

  /** Whether or not the modal is a pane style modal. */
  isPane?: boolean;

  /** The sliding direction of a pane style modal.
   * Default is right.
   * */
  panePosition?: 'right' | 'left';
}

/**
 * A client-only implementation of the Modal component.
 * It can only be rendered on the client side because it relies on the `document` object.
 */
export const ClientOnlyModal = observer(function Modal({
  className,
  style,
  isOpen,
  onClose,
  isPane = false,
  panePosition = 'right',
  children,
  onCloseFinished
}: IModalProps) {
  useConditionallyDisableScrolling(isOpen);

  const nodeRef = useRef(null);

  // The style class for the modal. This will determine the type of modal to render, 'modal' or 'pane'.
  // Default is 'modal'.
  const modalClass = !isPane ? 'modal' : 'pane';

  return createPortal(
    /**
     * The `CSSTransition` component manages the state of the animated transitions via CSS classes.
     * @see http://reactcommunity.org/react-transition-group/css-transition
     */
    <CSSTransition
      nodeRef={nodeRef}
      appear={isOpen} // Transition on initial render.
      in={isOpen}
      timeout={350}
      mountOnEnter
      onExited={onCloseFinished}
      classNames={{
        // States for when the modal is mounted to the DOM.
        appear: S[`${modalClass}-enter`],
        appearActive: S[`${modalClass}-enter-active`],
        appearDone: S[`${modalClass}-enter-done`],
        // States for when the modal being shown
        enter: S[`${modalClass}-enter`],
        enterActive: S[`${modalClass}-enter-active`],
        enterDone: S[`${modalClass}-enter-done`],
        // States for when the modal is being hidden and about to be removed from the DOM.
        exit: S[`${modalClass}-exit`],
        exitActive: S[`${modalClass}-exit-active`],
        exitDone: S[`${modalClass}-exit-done`]
      }}
    >
      {/**
       * The "modal" div represents the wrapper for the entire modal dialog box.
       * It's purpose is to contain the CSS transition state classes as passed by the
       * `CSSTransition` parent component.
       */}
      <div ref={nodeRef} className={S[`${modalClass}-enter`]}>
        {/**
         * The "container" div represents the wrapper for the entire viewport which
         * houses the modal dialog box.
         */}
        <div
          className={classes(S.container, isPane && S[`pane-${panePosition}`])}
          role="dialog"
          aria-modal="true"
        >
          {/**
           * The `Curtain` component represents the backdrop which covers and
           * "grays out" the entire viewport.
           */}
          <Curtain onClose={onClose} />
          {/** The "content" div represents the modal dialog box itself. */}
          <div
            className={classes(S.content, isPane && S.paneContent, className)}
            style={style}
            tabIndex={-1}
          >
            {children}
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.body
  );
});
