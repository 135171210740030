import type { InstanceTypeOf } from '@/type-utils';
import type { IHTTPErrorMetaData } from '@/utils/api-utils/IHTTPErrorMetaData';
import { errorFactory } from '@/utils/error-utils';
import { ResourceNotFoundError } from '@/utils/errors';

export const { LineItemNotFoundError } = errorFactory(
  'LineItemNotFoundError',
  ResourceNotFoundError,
  {
    mapsToHTTPError: 'NotFoundError'
  } as IHTTPErrorMetaData
);

/** When the gift card is already applied this error is thrown. */
export type LineItemNotFoundError = InstanceTypeOf<
  typeof LineItemNotFoundError
>;
