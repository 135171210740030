import config from './config';

const currentYear = new Date().getFullYear();

const footer = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** Shows an additional promo link on the footer UI. */
      showBottomPromoLink: false,

      /**
       * The main footer menu, displays headers which contain
       * groups of links which contain an id, a label, an href, and an optional newTab field.
       */
      footerMenu: [
        {
          id: 'help',
          label: 'Help',
          links: [
            {
              id: 'help-center',
              label: 'Customer Service',
              href: '/help-center',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'accessibility',
              label: 'Website Accessibility',
              href: '/accessibility',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'warranty',
              label: 'Warranty',
              href: '/warranty',
              newTab: false,
              needsLogin: false
            }
          ]
        },
        {
          id: 'orders',
          label: 'Orders',
          links: [
            {
              id: 'shipping-information',
              label: 'Shipping Information',
              href: '/shipping-information',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'order-status',
              label: 'Order Status',
              href: '/orders',
              newTab: false,
              needsLogin: true
            },
            {
              id: 'returns',
              label: 'Returns & Exchanges',
              href: '/returns',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'gift-cards',
              label: 'Gift Cards',
              href: '/giftcards',
              newTab: false,
              needsLogin: false
            }
          ]
        },
        {
          id: 'company',
          label: 'Company',
          links: [
            {
              id: 'brand',
              label: 'About Us',
              href: '/about',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'blog',
              label: 'Blog',
              href: '/blog',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'corporate-responsibility',
              label: 'Corporate Responsibility',
              href: '/corporate-responsibility',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'jobs',
              label: 'Jobs',
              href: 'https://www.deckers.com/careers',
              newTab: true,
              needsLogin: false
            },
            {
              id: 'stores',
              label: 'Store Location',
              href: '/storelocator',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'sanuk-student-discount',
              label: 'Student Discount',
              href: '/sanuk-student-discount',
              newTab: false,
              needsLogin: false
            }
          ]
        }
      ],
      /**
       * The footer submenu sits below the main footer menu. Each entry should be
       * componsed of an id, a label, and an href.
       */
      footerSubMenu: [
        {
          id: 'international',
          label: 'Visit our international sites',
          href: '/choose-country',
          newTab: false,
          needsLogin: false
        },
        {
          id: 'deckers',
          label: `© ${currentYear} Deckers Brands`,
          href: 'https://www.deckers.com',
          newTab: true,
          needsLogin: false
        },
        {
          id: 'privacy-policy',
          label: 'Privacy Policy',
          href: '/privacy-policy',
          newTab: false,
          needsLogin: false
        },
        {
          id: 'cookie-policy',
          label: 'Cookie Policy',
          href: '/cookies',
          newTab: false,
          needsLogin: false
        },
        {
          id: 'terms-and-conditions',
          label: 'Terms and Conditions',
          href: '/terms-and-conditions',
          newTab: false,
          needsLogin: false
        },
        {
          id: 'accessibility',
          label: 'Website Accessibility',
          href: '/accessibility',
          newTab: false,
          needsLogin: false
        },
        {
          id: 'transparency',
          label: 'CA Transparency Act',
          href: '/FY2024_statement_against_modern_slavery.pdf',
          newTab: true,
          needsLogin: false
        },
        {
          id: 'cookies-preference',
          label: 'Cookie Preferences',
          href: '#',
          newTab: false,
          needsLogin: false
        }
      ],
      /**
       * The link to accessibility page when click on accessibility icon.
       */
      accessibilityIcon: {
        href: '',
        newTab: false
      }
    }
  },
  'AHNU-US': {
    default: {
      /** Ahnu Footer Structure. */
      /**
       * The main footer menu, displays headers which contain
       * groups of links which contain an id, a label, an href, and an optional newTab field.
       */
      footerMenu: [
        {
          id: 'help',
          label: 'Help',
          links: [
            {
              id: 'help-center',
              label: 'Customer Service',
              href: '/help-center',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'accessibility',
              label: 'Website Accessibility',
              href: '/accessibility',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'warranty',
              label: 'Warranty',
              href: '/warranty',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'ahnu-affiliate-program',
              label: 'AHNU Affiliate Program',
              href: '/ahnu-affiliate-program',
              newTab: false,
              needsLogin: false
            }
          ]
        },
        {
          id: 'orders',
          label: 'Orders',
          links: [
            {
              id: 'shipping-information',
              label: 'Shipping Information',
              href: '/shipping-information',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'order-status',
              label: 'Order Status',
              href: '/orders',
              newTab: false,
              needsLogin: true
            },
            {
              id: 'returns',
              label: 'Returns & Exchanges',
              href: '/returns',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'gift-cards',
              label: 'Gift Cards',
              href: '/giftcards',
              newTab: false,
              needsLogin: false
            }
          ]
        },
        {
          id: 'company',
          label: 'Company',
          links: [
            {
              id: 'brand',
              label: 'About Us',
              href: '/about',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'corporate-responsibility',
              label: 'Corporate Responsibility',
              href: 'https://www.deckers.com/responsibility',
              newTab: true,
              needsLogin: false
            },
            {
              id: 'jobs',
              label: 'Jobs',
              href: 'https://www.deckers.com/careers',
              newTab: true,
              needsLogin: false
            }
          ]
        }
      ],
      /**
       * The footer submenu sits below the main footer menu. Each entry should be
       * componsed of an id, a label, and an href.
       */
      footerSubMenu: [
        {
          id: 'deckers',
          label: `© ${currentYear} Deckers Brands`,
          href: 'https://www.deckers.com',
          newTab: true,
          needsLogin: false
        },
        {
          id: 'privacy-policy',
          label: 'Privacy Policy',
          href: '/privacy-policy',
          newTab: false,
          needsLogin: false
        },
        {
          id: 'terms-and-conditions',
          label: 'Terms and Conditions',
          href: '/terms-and-conditions',
          newTab: false,
          needsLogin: false
        },
        {
          id: 'accessibility',
          label: 'Website Accessibility',
          href: '/accessibility',
          newTab: false,
          needsLogin: false
        },
        {
          id: 'transparency',
          label: 'CA Transparency Act',
          href: '/FY2024_statement_against_modern_slavery.pdf',
          newTab: true,
          needsLogin: false
        },
        {
          id: 'do-not-sell-my-personal-information',
          label: 'Do Not Sell My Personal Information',
          href: '/privacy-policy#PP17-5',
          newTab: true,
          needsLogin: false
        },
        {
          id: 'cookie-policy',
          label: 'Cookie Policy',
          href: '/cookies',
          newTab: false,
          needsLogin: false
        },
        {
          id: 'cookies-preference',
          label: 'Cookie Preferences',
          href: '#',
          newTab: false,
          needsLogin: false
        }
      ]
    }
  },
  'TEVA-US': {
    default: {
      /** Teva Footer Structure. */
      /**
       * The main footer menu, displays headers which contain
       * groups of links which contain an id, a label, an href, and an optional newTab field.
       */
      footerMenu: [
        {
          id: 'aboutus',
          label: 'ABOUT US',
          links: [
            {
              id: 'our-story',
              label: 'Our Story',
              href: '/about',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'corporate-responsibility',
              label: 'Corporate Responsibility',
              href: '/corporate-responsibility',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'careers',
              label: 'Careers',
              href: 'https://www.deckers.com/careers',
              newTab: true,
              needsLogin: false
            }
          ]
        },
        {
          id: 'quicklinks',
          label: 'QUICK LINKS',
          links: [
            {
              id: 'sizing',
              label: 'Sizing Information',
              href: '/sizing-information',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'stores',
              label: 'Stores',
              href: '/storelocator',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'giftcards',
              label: 'Gift Cards',
              href: '/giftcards',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'student-discount',
              label: 'Student Discount',
              href: '/teva-student-discount',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'pro-deals',
              label: 'Teva Pro Deals',
              href: '/IPA-Pro',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'affiliate-program',
              label: 'Teva Affiliate Program',
              href: '/teva-affiliate-program',
              newTab: false,
              needsLogin: false
            }
          ]
        },
        {
          id: 'help',
          label: 'HELP',
          links: [
            {
              id: 'returns',
              label: 'Returns & Exchanges',
              href: '/returns',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'customer-service',
              label: 'Customer Service',
              href: '/help-center',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'faqs',
              label: 'FAQs',
              href: '/help-center',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'order-status',
              label: 'Order Status',
              href: '/login',
              newTab: false,
              needsLogin: true
            },
            {
              id: 'shipping-information',
              label: 'Shipping Information',
              href: '/help-center?a=Shipping-Information---id--xapezn7-SAey1dLfTe8WlA',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'warranty',
              label: 'Warranties',
              href: '/teva-warranty/warranty',
              newTab: false,
              needsLogin: false
            }
          ]
        }
      ],
      /**
       * The footer submenu sits below the main footer menu. Each entry should be
       * componsed of an id, a label, and an href.
       */
      footerSubMenu: [
        {
          id: 'international',
          label: 'Visit our international sites',
          href: '/choose-country',
          newTab: false,
          needsLogin: false
        },
        {
          id: 'deckers',
          label: `© ${currentYear} Deckers Brands`,
          href: 'https://www.deckers.com',
          newTab: true,
          needsLogin: false
        },
        {
          id: 'privacy-policy',
          label: 'Privacy Policy',
          href: '/privacy-policy',
          newTab: false,
          needsLogin: false
        },
        {
          id: 'cookies-policy',
          label: 'Cookies Policy',
          href: '/cookies',
          newTab: false,
          needsLogin: false
        },
        {
          id: 'cookies-preference',
          label: 'Cookie Preferences',
          href: '#',
          newTab: false,
          needsLogin: false
        },
        {
          id: 'terms-and-conditions',
          label: 'Terms and Conditions',
          href: '/terms-and-conditions',
          newTab: false,
          needsLogin: false
        },
        {
          id: 'accessibility',
          label: 'Website Accessibility',
          href: '/accessibility',
          newTab: false,
          needsLogin: false
        },
        {
          id: 'transparency',
          label: 'CA Transparency Act',
          href: '/FY2024_statement_against_modern_slavery.pdf',
          newTab: true,
          needsLogin: false
        },
        {
          id: 'do-not-sell-my-personal-information',
          label: 'Do Not Sell My Personal Information',
          href: '/privacy-policy#PP17-5',
          newTab: true,
          needsLogin: false
        }
      ],
      accessibilityIcon: {
        href: 'https://www.levelaccess.com/a/teva/',
        newTab: true
      }
    }
  },
  'HOKA-US': {
    default: {
      /** Hoka Footer Structure. */

      showBottomPromoLink: true,
      /**
       * The main footer menu, displays headers which contain
       * groups of links which contain an id, a label, an href, and an optional newTab field.
       */
      footerMenu: [
        {
          id: 'company',
          label: 'Company',
          links: [
            {
              id: 'our-story',
              label: 'Our Story',
              href: '/about',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'our-impact',
              label: 'Our Impact',
              href: '/hoka-impact',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'blog',
              label: 'Blog',
              href: '/blog',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'careers',
              label: 'Careers',
              href: '/careers',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'affiliate-program',
              label: 'Affiliate Program',
              href: '/hoka-affiliate-program',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'product-testing-program',
              label: 'Product Testing Program',
              href: '/product-testing-program',
              newTab: false,
              needsLogin: false
            }
          ]
        },
        {
          id: 'customer-care',
          label: 'Customer Care',
          links: [
            {
              id: 'help-center',
              label: 'Help Center',
              href: '/help-center',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'membership',
              label: 'Membership',
              href: '/membership',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'guarantee',
              label: 'Fly for 30 Guarantee',
              href: '/guarantee',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'returns-exchanges',
              label: 'Returns & Exchanges',
              href: '/returns',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'orders',
              label: 'Order Status',
              href: '/orders',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'warranty',
              label: 'Warranty',
              href: '/warranty',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'corporate-sales',
              label: 'Corporate Sales',
              href: '/corporate-sales',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'counterfeit',
              label: 'Counterfeit Websites & Phishing',
              href: '/hoka-product-authenticity',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'giftcards',
              label: 'Gift Cart Balance',
              href: '/giftcards',
              newTab: false,
              needsLogin: false
            }
          ]
        },
        {
          id: 'resources',
          label: 'Resources',
          links: [
            {
              id: 'sizing-information',
              label: 'Sizing Guide',
              href: '/sizing-information',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'truefit',
              label: 'True Fit',
              href: '/truefit-size-guides',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'care-and-cleaning',
              label: 'Care & Cleaning',
              href: '/care-and-cleaning',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'care-and-cleaning',
              label: 'Care & Cleaning',
              href: '/help-center.html?a=How-Can-I-Wash-My-Hoka-Footwear---id--ap28lxmxQbmApfEnyCsSGQ',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'free-shipping',
              label: 'Free Shipping & Free Returns',
              href: '/guarantee',
              newTab: false,
              needsLogin: false
            }
          ]
        },
        {
          id: 'shop',
          label: 'Shop',
          links: [
            {
              id: 'shoe-finder',
              label: 'Shoe Finder',
              href: '/hoka-shoe-finder',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'gift-cards',
              label: 'Gift Cards',
              href: '/hoka-digital-gift-card',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'sale',
              label: 'SALE',
              href: '/sale',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'stores',
              label: 'Find a Store',
              href: '/stores',
              newTab: false,
              needsLogin: false
            },
            {
              id: 'pro-deal',
              label: 'Pro Deal',
              href: '/IPA-Pro',
              newTab: false,
              needsLogin: false
            }
          ]
        }
      ]
    }
  }
});

export default footer;
