import type { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { InvalidArgumentError } from '@/utils/errors';

export const { UnableToFindOrderError } = errorFactory(
  'UnableToFindOrderError',
  InvalidArgumentError
);

/**
 * An error for when order lookup fails to find the order or email address. Or the zip code doesn't match.
 */
export type UnableToFindOrderError = InstanceTypeOf<
  typeof UnableToFindOrderError
>;
