'use client';

import { useCallback, type FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import {
  PayPalButtons,
  usePayPalScriptReducer,
  PayPalButtonsComponentProps
} from '@paypal/react-paypal-js';

import PayPalNVPService from '@/services/isomorphic/integrations/PayPalNVPService';
import type { IGetExpressCheckoutDetailsResponse } from '@/services/serverless/integrations/ServerPayPalNVPService';
import type CartVM from '@/react/view-models/CartVM';

import type { Country } from '@/constructs/Country';
import type { USProvince } from '@/constructs/provinces/US/USProvince';

import type { MaybePromise } from '@/type-utils';
import { isNullOrEmpty } from '@/utils/null-utils';
import { InvalidStateError } from '@/utils/errors';
import S, { buttonHeight } from './styles.module.scss';

export interface IPayPalButtonProps {
  /** The cart in use, to create the order from it. */
  cart: CartVM;

  /**
   * The mode to use, which will determine the behavior of the button.
   *
   * - `normal`: Meant to be used on "full checkout", where the user fills in
   *   their information via the checkout form and _then_ selects PayPal as the
   *   payment method. Bear in mind that the user won't be able to override the
   *   shipping address fields in the PayPal modal, since they would have
   *   already filled them out in the checkout form.
   *
   * - `express`: Meant to be used on "express checkout", where the user is
   *   taken to the PayPal modal right away to fill in their information. In
   *   this mode, the user must enter the shipping address from the PayPal
   *   modal, either by selecting one of the addresses saved on their account or
   *   by entering a new one.
   */
  mode?: 'normal' | 'express';

  /**
   * Callback to run when the PayPal transaction has been approved by the user.
   *
   * @param result - The result from the `GetExpressCheckoutDetails` call made after the
   * user approves the transaction.
   *
   * @see [GetExpressCheckoutDetails API Operation (NVP)](https://developer.paypal.com/api/nvp-soap/get-express-checkout-details-nvp/)
   * @see {@link PayPalNVPService.getExpressCheckoutDetails}
   *
   * @returns Either a promise that must resolve after order placement succeeds,
   * or nothing (`void`).
   */
  onApprove?: (
    result: IGetExpressCheckoutDetailsResponse
  ) => MaybePromise<void>;

  /**
   * Callback to run when the PayPal transaction is cancelled by the user.
   */
  onCancel?: () => void;
}

/** Renders a button that allows the user to pay an order with PayPal. */
export const PayPalButton: FunctionComponent<IPayPalButtonProps> = observer(
  ({ cart, mode = 'normal', onApprove, onCancel }) => {
    const [{ isPending }] = usePayPalScriptReducer();

    /**
     * Util function to get the current cart as a PayPal Express Checkout.
     *
     * @param omitShippingAddress - If the current shipping address should be
     * omitted in the object. Set this to `true` when pushing updates without a
     * complete address.
     */
    const getCartAsExpressCheckout = useCallback(
      (omitShippingAddress = false) => {
        const dto = cart.toDTO();

        if (!dto) {
          throw new InvalidStateError(
            'Cannot get current cart as a PayPal Express Checkout object:' +
              'There is no cart currently available.'
          );
        }

        return PayPalNVPService.expressCheckoutFromDECACart(dto, {
          isExpress: mode === 'express',
          omitShippingAddress
        });
      },
      [cart, mode]
    );

    const createOrder = useCallback<
      Exclude<PayPalButtonsComponentProps['createOrder'], undefined>
    >(async () => {
      const { TOKEN } = await PayPalNVPService.setExpressCheckout(
        // Omit the shipping address when creating an order for express checkout.
        // This is to force the PayPal modal to provide an address.
        getCartAsExpressCheckout(mode === 'express')
      );

      return TOKEN;
    }, [getCartAsExpressCheckout, mode]);

    const onApproveInternal = useCallback(
      async (data: { orderID: string }) => {
        if (onApprove) {
          const expressCheckoutDetails =
            await PayPalNVPService.getExpressCheckoutDetails({
              TOKEN: data.orderID
            });

          await onApprove(expressCheckoutDetails);
        }
      },
      [onApprove]
    );

    const onShippingAddressChange = useCallback<
      Exclude<PayPalButtonsComponentProps['onShippingAddressChange'], undefined>
    >(
      async (data) => {
        const { shippingAddress, orderID } = data;

        // Notice that these callbacks are not passed the actual lines of the
        // address or the names of the recipient.
        const { city, countryCode, postalCode, state } = shippingAddress;

        cart.setShipToAddress({
          // Set these fields as empty string in the meantime. The complete
          // address will come in the `getExpressCheckoutDetails` response when
          // the order is finally approved.
          addressLine1: '',
          firstName: '',
          lastName: '',

          city,
          country: countryCode as Country,
          zipPostalCode: postalCode,
          stateProvince: state as USProvince
        });

        if (isNullOrEmpty(orderID)) {
          throw new InvalidStateError(
            'Cannot update shipping address: There is no orderID in the data' +
              ' received.'
          );
        }

        await PayPalNVPService.setExpressCheckout({
          ...getCartAsExpressCheckout(true),
          TOKEN: orderID
        });
      },
      [cart, getCartAsExpressCheckout]
    );

    // The `buttonHeight` variable exported from the SCSS module should be parsed
    // as an integer. Any non-digit character should also be removed, leaving only
    // the number part of it.
    const parsedButtonHeight = parseInt(buttonHeight.replaceAll(/\D/g, ''), 10);

    return (
      <div className={S.payPalButton}>
        {isPending && <div className={S.spinner} />}

        <PayPalButtons
          fundingSource="paypal"
          style={{
            layout: 'horizontal',
            label: 'checkout',
            tagline: false,
            height: parsedButtonHeight,
            disableMaxWidth: true
          }}
          createOrder={createOrder}
          onApprove={onApproveInternal}
          onShippingAddressChange={onShippingAddressChange}
          onCancel={() => {
            if (onCancel) onCancel();
          }}
        />
      </div>
    );
  }
);
