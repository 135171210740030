import axios from 'axios';

import Service from '@/services/Service';
import ServerPayPalNVPService, {
  type ISetExpressCheckoutParams,
  type ISetExpressCheckoutResponse,
  type IGetExpressCheckoutDetailsParams,
  type IGetExpressCheckoutDetailsResponse
} from '@/services/serverless/integrations/ServerPayPalNVPService';

import type { ICart } from '@/services/models/Cart';
import type { DTO, Nullable } from '@/type-utils';
import { MoneyModel } from '@/services/models/Money';
import { removeNullish } from '@/utils/object-utils';
import { InvalidArgumentError } from '@/utils/errors';
import type { USProvince } from '@/constructs/provinces/US/USProvince';
import type { Country } from '@/constructs/Country';
import type { IOrderShippingInfo } from '@/services/models/Order';
import type { IAddress } from '@/services/models/Address';
import { isNullOrEmpty } from '@/utils/null-utils';
import { LineItemModel } from '@/services/models/Cart/LineItem';
import { EnvironmentService } from '../../EnvironmentService';

/**
 * Isomorphic integration service for the
 * [PayPal NVP API](https://developer.paypal.com/api/nvp-soap/nvp/).
 *
 * @see {@link ServerPayPalNVPService} for the server-side integration service.
 */
export class PayPalNVPService extends Service {
  private client = axios.create({ baseURL: '/api/paypal/nvp' });

  /**
   * Given a DECA Cart, transform it into a set of parameters that can be used
   * to place a
   * [`SetExpressCheckout`](https://developer.paypal.com/api/nvp-soap/set-express-checkout-nvp/)
   * request with PayPal.
   *
   * **NOTE:** All of these PayPal calls have names that include
   * "ExpressCheckout", but they are used for _both_ normal checkout and express
   * checkout.
   *
   * @param decaCart - The DECA Cart to transform, as an {@link ICart} DTO.
   * @param options - Additional options for the transform.
   *
   * @returns An {@link ISetExpressCheckoutParams} object that can be then used
   * to make a `SetExpressCheckout` request.
   */
  public expressCheckoutFromDECACart(
    decaCart: DTO<ICart>,
    options: {
      /**
       * Set to `true` when doing express checkout. This will slightly alter the
       * parameters in the final express checkout object.
       */
      isExpress: boolean;

      /**
       * Will not include the shipping address in the final express checkout
       * object.
       *
       * Set to `true` when getting an express checkout object with an
       * incomplete address.
       */
      omitShippingAddress: boolean;
    } = {
      isExpress: false,
      omitShippingAddress: false
    }
  ): ISetExpressCheckoutParams {
    const {
      total,
      subtotal,
      discount,
      tax,
      shippingCost,
      shipToAddress,
      items
    } = decaCart;

    const { isExpress, omitShippingAddress } = options;

    const subtotalMinusDiscounts = MoneyModel.subtract(subtotal, discount);

    const params: ISetExpressCheckoutParams = {
      RETURNURL: 'https://development.ahnu.co',
      CANCELURL: 'https://development.ahnu.co',
      PAYMENTREQUEST_0_PAYMENTACTION: 'Order',

      PAYMENTREQUEST_0_AMT: MoneyModel.from(total).toFixed().amount,
      PAYMENTREQUEST_0_ITEMAMT: subtotalMinusDiscounts.toFixed().amount,
      PAYMENTREQUEST_0_SHIPPINGAMT:
        MoneyModel.from(shippingCost).toFixed().amount,
      PAYMENTREQUEST_0_TAXAMT: MoneyModel.from(tax).toFixed().amount,
      PAYMENTREQUEST_0_CURRENCYCODE: total.currency,

      // If doing an Express Checkout, show the shipping address fields in the
      // PayPal modal. If not, do NOT show the address fields at all.
      // https://developer.paypal.com/api/nvp-soap/set-express-checkout-nvp/#:~:text=and%206.-,NOSHIPPING,-Determines%20whether%20PayPal
      NOSHIPPING: isExpress ? '2' : '1',

      ...(shipToAddress &&
        !omitShippingAddress && {
          // If specifying an address, make it override the address that would be
          // otherwise be set by PayPal.
          // See https://developer.paypal.com/api/nvp-soap/set-express-checkout-nvp/#:~:text=byte%20numeric%20characters.-,ADDROVERRIDE,-(Optional)%20Determines
          ADDROVERRIDE: '1',
          PAYMENTREQUEST_0_SHIPTONAME: `${shipToAddress.firstName} ${shipToAddress.lastName}`,
          PAYMENTREQUEST_0_SHIPTOSTREET: shipToAddress.addressLine1,
          PAYMENTREQUEST_0_SHIPTOSTREET2: shipToAddress.addressLine2,
          PAYMENTREQUEST_0_SHIPTOCITY: shipToAddress.city,
          PAYMENTREQUEST_0_SHIPTOSTATE: shipToAddress.stateProvince,
          PAYMENTREQUEST_0_SHIPTOZIP: shipToAddress.zipPostalCode,
          PAYMENTREQUEST_0_SHIPTOCOUNTRYCODE: shipToAddress.country
        })
    };

    // Map each line so the user gets a detailed breakdown in the PayPal modal.
    items.forEach((item, idx) => {
      const { name, quantity, unitTax, netUnitPrice } =
        LineItemModel.from(item);

      params[`L_PAYMENTREQUEST_0_NAME${idx}`] = name;
      params[`L_PAYMENTREQUEST_0_AMT${idx}`] = netUnitPrice.toFixed().amount;
      params[`L_PAYMENTREQUEST_0_NUMBER${idx}`] = idx.toString();
      params[`L_PAYMENTREQUEST_0_QTY${idx}`] = quantity.toString();
      params[`L_PAYMENTREQUEST_0_TAXAMT${idx}`] = unitTax.toFixed().amount;
    });

    return removeNullish(params);
  }

  /**
   * Given a `GetExpressCheckoutDetails` response from PayPal, extract the
   * shipping address of the customer from it.
   *
   * @param details - The {@link IGetExpressCheckoutDetailsResponse} returned by PayPal.
   *
   * @returns An {@link IAddress} object with the shipping address of the customer.
   *
   * @throws An {@link InvalidArgumentError} if the address is either not
   * confirmed or present in the provided object.
   */
  public getShippingAddressFromExpressCheckoutDetails(
    details: IGetExpressCheckoutDetailsResponse
  ): Nullable<IAddress> {
    const {
      PAYMENTREQUEST_0_SHIPTONAME,
      PAYMENTREQUEST_0_SHIPTOSTREET,
      PAYMENTREQUEST_0_SHIPTOSTREET2,
      PAYMENTREQUEST_0_SHIPTOCITY,
      PAYMENTREQUEST_0_SHIPTOSTATE,
      PAYMENTREQUEST_0_SHIPTOZIP,
      PAYMENTREQUEST_0_SHIPTOCOUNTRYCODE,
      PAYMENTREQUEST_0_ADDRESSSTATUS
    } = details;

    if (
      isNullOrEmpty(PAYMENTREQUEST_0_SHIPTONAME) ||
      isNullOrEmpty(PAYMENTREQUEST_0_SHIPTOSTREET) ||
      isNullOrEmpty(PAYMENTREQUEST_0_SHIPTOCITY) ||
      isNullOrEmpty(PAYMENTREQUEST_0_SHIPTOSTATE) ||
      isNullOrEmpty(PAYMENTREQUEST_0_SHIPTOZIP) ||
      isNullOrEmpty(PAYMENTREQUEST_0_SHIPTOCOUNTRYCODE)
    ) {
      // There is no address in this PayPal response.`
      return null;
    }

    if (PAYMENTREQUEST_0_ADDRESSSTATUS !== 'Confirmed') {
      throw new InvalidArgumentError(
        'Cannot extract address from a PayPal `GetExpressCheckoutDetails` ' +
          'reponse: The address is NOT CONFIRMED in the provided express ' +
          'checkout details. Please make sure the order has been approved by ' +
          'the user.'
      );
    }

    // PayPal NVP returns address names as a single string. This is not ideal,
    // but it should display the first name of the user correctly in most cases.
    //
    // Real solution: upgrade to PayPal's newer API.
    const [firstName, ...otherNames] = PAYMENTREQUEST_0_SHIPTONAME.split(' ');

    return {
      addressLine1: PAYMENTREQUEST_0_SHIPTOSTREET,
      addressLine2: PAYMENTREQUEST_0_SHIPTOSTREET2 || undefined,
      city: PAYMENTREQUEST_0_SHIPTOCITY,
      stateProvince: PAYMENTREQUEST_0_SHIPTOSTATE as USProvince,
      country: PAYMENTREQUEST_0_SHIPTOCOUNTRYCODE as Country,
      zipPostalCode: PAYMENTREQUEST_0_SHIPTOZIP,

      firstName,
      lastName: otherNames.join(' ')
    };
  }

  /**
   * Given a `GetExpressCheckoutDetails` response from PayPal, extract the
   * billing address of the customer from it.
   *
   * @param details - The {@link IGetExpressCheckoutDetailsResponse} returned by PayPal.
   *
   * @returns An {@link IAddress} object with the billing address of the customer.
   *
   * @throws An {@link InvalidArgumentError} if the address is either not
   * confirmed or present in the provided object.
   */
  public getBillingAddressFromExpressCheckoutDetails(
    details: IGetExpressCheckoutDetailsResponse
  ): Nullable<IAddress> {
    const { BILLINGNAME, STREET, CITY, STATE, ZIP, COUNTRY } = details;

    if (
      isNullOrEmpty(BILLINGNAME) ||
      isNullOrEmpty(STREET) ||
      isNullOrEmpty(CITY) ||
      isNullOrEmpty(STATE) ||
      isNullOrEmpty(ZIP) ||
      isNullOrEmpty(COUNTRY)
    ) {
      // There is no address in this PayPal response.
      return null;
    }

    // PayPal NVP returns address names as a single string. This is not ideal,
    // but it should display the first name of the user correctly in most cases.
    //
    // Real solution: upgrade to PayPal's newer API.
    const [firstName, ...otherNames] = BILLINGNAME.split(' ');

    return {
      addressLine1: STREET,
      city: CITY,
      stateProvince: STATE as USProvince,
      country: COUNTRY as Country,
      zipPostalCode: ZIP,

      firstName,
      lastName: otherNames.join(' ')
    };
  }

  /**
   * Given a `GetExpressCheckoutDetails` response from PayPal, extract an
   * {@link IOrderShippingInfo} object from it.
   *
   * @param details - The {@link IGetExpressCheckoutDetailsResponse} returned by
   * PayPal.
   * @param cart - The cart used to create the PayPal order.
   *
   * @returns An {@link IOrderShippingInfo} object with the shipping info of
   * the customer.
   *
   * @throws An {@link InvalidArgumentError} if the provided cart doesn't have a
   * selected shipping method, or if the provided checkout details object does
   * not contain an address.
   */
  public getOrderShippingInfoFromExpressCheckoutDetails(
    details: IGetExpressCheckoutDetailsResponse,
    cart: ICart
  ): IOrderShippingInfo {
    const { EMAIL, PHONENUM } = details;

    if (!cart.selectedShippingMethod) {
      throw new InvalidArgumentError(
        'Cannot get shipping info from Express Checkout Details: The ' +
          'provided cart does not have a shipping method selected.'
      );
    }

    const address = this.getShippingAddressFromExpressCheckoutDetails(details);

    if (!address) {
      throw new InvalidArgumentError(
        'Cannot get shipping info from Express Checkout Details: The ' +
          'provided details object does not contain an address.'
      );
    }

    return {
      email: EMAIL,
      shippingMethod: cart.selectedShippingMethod.uid,
      phoneNumber: PHONENUM,
      address
    };
  }

  /**
   * Initializes a transaction with PayPal, which will allow the user to be
   * redirected to the PayPal modal to complete the purchase.
   *
   * Maps to the
   * [`SetExpressCheckout`](https://developer.paypal.com/api/nvp-soap/set-express-checkout-nvp/)
   * call of the PayPal NVP API.
   *
   * **NOTE:** All of these PayPal calls have names that include
   * "ExpressCheckout", but they are used for _both_ normal checkout and express
   * checkout.
   *
   * @param params - The {@link ISetExpressCheckoutParams params} to use for the
   * call.
   *
   * @returns The data sent back by PayPal, as an
   * {@link ISetExpressCheckoutResponse} object.
   */
  public async setExpressCheckout(
    params: ISetExpressCheckoutParams
  ): Promise<ISetExpressCheckoutResponse> {
    if ((typeof window === "undefined")) {
      return ServerPayPalNVPService.setExpressCheckout(params);
    }

    const res = await this.client.post<ISetExpressCheckoutResponse>(
      'set-express-checkout',
      params
    );

    return res.data;
  }

  /**
   * Retrieves detailed information about a PayPal transaction.
   *
   * Maps to the
   * [`GetExpressCheckoutDetails`](https://developer.paypal.com/api/nvp-soap/get-express-checkout-details-nvp/)
   * call of the PayPal NVP API.
   *
   * **NOTE:** All of these PayPal calls have names that include
   * "ExpressCheckout", but they are used for _both_ normal checkout and express
   * checkout.
   *
   * @param params - The {@link IGetExpressCheckoutDetailsResponse params} to
   * use for the call.
   *
   * @returns The data sent back by PayPal, as an
   * {@link IGetExpressCheckoutDetailsResponse} object.
   */
  public async getExpressCheckoutDetails(
    params: IGetExpressCheckoutDetailsParams
  ): Promise<IGetExpressCheckoutDetailsResponse> {
    if ((typeof window === "undefined")) {
      return ServerPayPalNVPService.getExpressCheckoutDetails(params);
    }

    const res = await this.client.post<IGetExpressCheckoutDetailsResponse>(
      'get-express-checkout-details',
      params
    );

    return res.data;
  }
}

export default new PayPalNVPService();
