import config from '../config';

const navigation = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /* Local storage key to store current sidebar state either closed or opened. */
      localStorageKey: 'conductorMenuState',
      /* Text for close menu in navigation bar. */
      closeMenu: {
        text: 'Close menu'
      },
      /* Text for back menu in mobile navigation bar. */
      backMenu: {
        text: 'Back'
      },
      /**
       * The main navigation menu, displays headers which contain
       * groups of links which contain an id, a label, an href, and an optional newTab field.
       */
      navigationMenu: [
        {
          id: 'orders',
          label: 'Orders',
          icon: 'Storefront',
          href: '#',
          newTab: false,
          links: [
            {
              id: 'order-search',
              label: 'Order Search',
              icon: '',
              href: '/orders/order',
              newTab: false
            },
            {
              id: 'events-summary',
              label: 'Events Summary',
              href: '/orders/summary',
              newTab: false,
              icon: ''
            },
            {
              id: 'human-workflow',
              label: 'Human Workflow',
              href: '/orders/human-workflow',
              newTab: false,
              icon: ''
            }
          ]
        },
        {
          id: 'customers',
          label: 'Customers',
          icon: 'BoundingBox',
          href: '#',
          newTab: false,
          links: [
            {
              id: 'customer-search',
              label: 'Customer Search',
              href: '/customers/customer',
              newTab: false,
              icon: ''
            },
            {
              id: 'loyalty-search',
              label: 'Loyalty Search',
              href: '/customers/loyalty/search',
              newTab: false,
              icon: ''
            },
            {
              id: 'loyalty-enroll',
              label: 'Loyalty Enroll',
              href: '/customers/loyalty/enroll',
              newTab: false,
              icon: ''
            },
            {
              id: 'mail-search',
              label: 'Mail Search',
              href: '/customers/mail',
              newTab: false,
              icon: ''
            }
          ]
        },
        {
          id: 'inventory',
          label: 'Inventory',
          icon: 'FileTray',
          href: '#',
          newTab: false,
          needsLogin: false,
          links: [
            {
              id: 'fulfillment-center',
              label: 'Fulfillment Center',
              href: '/inventory/fulfillment-centers',
              newTab: false,
              icon: ''
            },
            {
              id: 'consolidated-inventory',
              label: 'Consolidated Inventory',
              href: '/inventory/consolidated-inventory',
              newTab: false,
              icon: ''
            },
            {
              id: 'safety-stock',
              label: 'Safety Stock',
              href: '/inventory/inventory-safety-stock',
              newTab: false,
              icon: ''
            },
            {
              id: 'inventory-group-settings',
              label: 'Inventory Group Settings',
              href: '/inventory/inventory-group-settings',
              newTab: false,
              icon: ''
            },
            {
              id: 'pre-order-management',
              label: 'Pre-Order Management',
              href: '/inventory/pre-order-management',
              newTab: false,
              icon: ''
            }
          ]
        },
        {
          id: 'legacy-pages',
          label: 'Legacy Pages',
          icon: 'EmptyStar',
          href: '#',
          newTab: false,
          needsLogin: false,
          links: [
            {
              id: 'legacy-returns',
              label: 'Returns',
              href: 'https://dwwebservices.deckers.com/DOMS',
              newTab: true,
              needsLogin: false,
              icon: ''
            },
            {
              id: 'legacy-payments',
              label: 'Payments',
              href: 'https://dwwebservices.deckers.com/DOMS',
              newTab: true,
              needsLogin: false,
              icon: ''
            },
            {
              id: 'legacy-omni',
              label: 'Omni',
              href: 'https://dwwebservices.deckers.com/DOMS',
              newTab: true,
              needsLogin: false,
              icon: ''
            },
            {
              id: 'legacy-admin',
              label: 'Admin',
              href: 'https://dwwebservices.deckers.com/DOMS',
              newTab: true,
              needsLogin: false,
              icon: ''
            }
          ]
        }
      ]
    }
  }
});

export default navigation;
