'use client';

import { useContext, type FC } from 'react';
import { NotificationContext } from '../../controller';
import Notification from '../Notification';

/** A component that shows the first notification in the queue. */
export const ShowFirstNotification: FC = () => {
  const { notificationQueue } = useContext(NotificationContext);
  return (
    <Notification
      notification={notificationQueue.length > 0 ? notificationQueue[0] : null}
    />
  );
};
