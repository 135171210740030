/**
 * The identifier for a particular styled experience. There should only be one type of styled experience
 * used on a page at a time.
 */
export type StyledExperienceType = 'header' | 'footer';

/**
 * An identifier that corresponds to a variation defined in our personalization platform.
 */
export type StyledExperienceDecisionId =
  `styledExperience-${StyledExperienceType}`;

/**
 * An array of all the possible styled experience types.
 */
export type StyledExperienceDecisions = Array<StyledExperienceDecisionId>;

/**
 * The experience map is a place to list experiences with decision options relevant to each.
 * This way the developer can define what kind of options are possible when creating the actual
 * experience component.
 */
export const experienceToDecisionsMap = {
  /** A test experience to be used to test Personalization and DY functionality. */
  testExperience: ['decision-testExperience-1', 'decision-testExperience-2'],
  /** A second test experience to be used to test Personalization and DY functionality. */
  testExperience2: ['decision-testExperience2-1', 'decision-testExperience2-2'],

  /** A manual recommendation for the homepage recommendations context. */
  directRecommendations: ['homepage'],

  /** Styled experiences for overriding the default styles of components. */
  styledExperiences: ['styledExperience-header', 'styledExperience-footer'],

  /** A test experience for the header position. */
  'ab-header-position': [
    'ab-header-decision-sticky',
    'ab-header-decision-static'
  ],

  /** A test experience for the shoe height appearance on pdp. One shows a button, the other an image. */
  'ab-pdp-shoe-height-appearance': [
    'ab-pdp-shoe-height-image',
    'ab-pdp-shoe-height-button'
  ],

  /** A test experience for the text position on the content block. */
  'ab-content-text-position': [
    'ab-content-text-top-left',
    'ab-content-text-bottom-right'
  ]
} as const satisfies Record<string, Array<string>>;

/** The type of the experience to decisions mapping. */
export type IExperienceToDecisionsMapping = typeof experienceToDecisionsMap;
