import { memo } from 'react';

import { Builder, withChildren } from '@builder.io/react';
import dynamic from 'next/dynamic';

import { uiImages } from '@/react/components/content/registry/data';

/**
 * Register the AboutPage component.
 * This is a custom component that was built for Ahnu prior to the initial builder.io implementation.
 */
const register = (): void => {
  const component = memo(dynamic(async () => import('./index')));
  const componentWithChildren = withChildren(component);

  Builder.registerComponent(componentWithChildren, {
    name: 'AboutPage',
    friendlyName: 'About Page Content (Ahnu)',
    static: true,
    image: uiImages.sectionImage
  });
};

export default register;
