'use client';

import { FunctionComponent } from 'react';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { useLocale } from '@/react/hooks/useLocale';
import Head from 'next/head';
import ScriptLoader from '../../utility/ScriptLoader';

/**
 * Adds the dynamic yield scripts to manage dynamic yield analytics.
 *
 * All scripts are loaded before the page is interactive and are not async,
 * to follow documentation recommendations.
 *
 * See https://dy.dev/docs/implement-script#best-practices for more information.
 *
 * At least one DY context needs to be set before the DY API is loaded in order to load the correct data.
 * Added a script to set the context as { type: "OTHER" } if no context is set.
 *
 * See https://dy.dev/docs/code-context and https://dy.dev/docs/script-overview for more information.
 */
const DynamicYield: FunctionComponent = () => {
  const { language, country } = useLocale();
  const config = ConfigurationService.getConfig(
    'dynamicYield',
    language,
    country
  );

  const isDYEnabled = config.getSetting('isDYEnabled').value;
  const isDYClientEnabled = config.getSetting('isDYClientEnabled').value;
  const siteID = config.getSetting('siteID').value;

  return (
    <>
      {isDYEnabled && isDYClientEnabled && (
        <>
          <Head>
            <link rel="preconnect" href="//cdn.dynamicyield.com" />
            <link rel="preconnect" href="//st.dynamicyield.com" />
            <link rel="preconnect" href="//rcom.dynamicyield.com" />
            <link rel="dns-prefetch" href="//cdn.dynamicyield.com" />
            <link rel="dns-prefetch" href="//st.dynamicyield.com" />
            <link rel="dns-prefetch" href="//rcom.dynamicyield.com" />
          </Head>
          <ScriptLoader
            async={false}
            strategy="beforeInteractive"
            type="text/javascript"
            useNext
          >
            {`window.DY=window.DY||{},window.DY.recommendationContext||(window.DY.recommendationContext={type:"OTHER"});`}
          </ScriptLoader>
          <ScriptLoader
            async={false}
            strategy="beforeInteractive"
            type="text/javascript"
            src={`//cdn.dynamicyield.com/api/${siteID}/api_static.js`}
            useNext
          />
          <ScriptLoader
            async={false}
            strategy="beforeInteractive"
            type="text/javascript"
            src={`//cdn.dynamicyield.com/api/${siteID}/api_dynamic.js`}
            useNext
          />
        </>
      )}
    </>
  );
};

export default DynamicYield;
