'use client';

import {
  NotificationContext,
  useNotificationController
} from '@/react/components/utility/notifications/controller';

import { Unprintable } from '@/react/components/utility/Unprintable';
import { NotificationBanner } from '@/react/components/utility/notifications/components/NotificationBanner';
import { useDeferredValue } from 'react';

/**
 * Component that encapsulates the other components of the Notifications
 * API.
 *
 * Functionally, it ensures that all of the aforementioned components are called
 * together from a single
 * [Client Component](https://nextjs.org/docs/app/building-your-application/rendering/client-components);
 * mainly because it is placed on Server-rendered Layouts.
 */
export const NotificationsManager: React.FC<React.PropsWithChildren> = ({
  children
}) => {
  // All notification logic is encapsulated in the useNotificationController hook
  // to keep this component light and simple.
  const notificationController = useNotificationController();

  const deferredNotificationController = useDeferredValue(
    notificationController
  );

  return (
    <NotificationContext.Provider value={deferredNotificationController}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationsManager;
