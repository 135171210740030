import type { IProduct } from '@/services/models/Product';
import { useMemo } from 'react';
import type { Nullable } from '@/type-utils';
import ProductTileVM from '../view-models/ProductTileVM';

/**
 * Creates a product tile view model from a given {@link IProduct}.
 * @param product - The product to create a view model from.
 * @returns A product view model.
 */
export const useProductTile = (
  product: Nullable<IProduct>
): Nullable<ProductTileVM> => {
  const productTileVM = useMemo(() => {
    if (product) {
      const productTileViewModel = new ProductTileVM(product);

      return productTileViewModel;
    }

    return undefined;
  }, [product]);

  return productTileVM;
};
