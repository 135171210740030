'use client';

import { SpeedInsights } from '@vercel/speed-insights/react';
import { enableStaticRendering } from 'mobx-react-lite';
import { usePathname } from 'next/navigation';
import NextTopLoader from 'nextjs-toploader';
import { Suspense } from 'react';
import { useRouter } from '@/react/utils/router-utils/useRouter';

// Added to resolve Logger error when using in the browser: https://github.com/winstonjs/winston/issues/1354#issuecomment-546271943
import { Datadog } from '@/react/components/Datadog';

import { Osano } from '@/react/components/cookie-preferences';
import Forter from '@/react/components/fraud/Forter';
import DynamicYield from '@/react/components/personalization/DynamicYield';
import GTM from '@/react/components/user-action/GTM';

import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import {
  ReactHooksWrapper,
  setHook
} from '@/services/utils/react-utils/hook-utils';

import { GlobalProvider } from '@/react/providers/global/GlobalProvider';

import { GlobalErrorAlertProvider } from '@/react/components/core-ui/ErrorAlert/GlobalErrorAlertProvider';
import { RootErrorBoundary } from '@/react/components/errors/boundaries/RootErrorBoundary';
import { TopErrorAlertList } from '@/react/components/errors/devtools/TopErrorAlertList';
import { TopErrorProvider } from '@/react/components/errors/devtools/TopErrorProvider';
import { PageProvider } from '@/react/components/templates/context/PageProvider';
import { NotificationsManager } from '@/react/components/utils/NotificationsManager';
import { RouterEventProvider } from '@/react/providers/router-events/RouterEventProvider';
import { RouterEvents } from '@/react/providers/router-events/RouterEvents';
import { PageService } from '@/services/isomorphic/PageService';
import {
  headerLoadingBarColor,
  headerLoadingBarHeight
} from '@/styles/variables.module.scss';
import { InvalidStateError } from '@/utils/errors';

// Prevent MobX from leaking memory when rendering server-side
enableStaticRendering((typeof window === "undefined"));

export interface IClientRootProps {
  /**
   * The children of the ClientRoot component.
   */
  children: React.ReactNode;
}

/**
 * This is the top-most Client Component.
 * @throws If there is no page object on the pages service.
 */
const ClientRoot: React.FC<IClientRootProps> = ({ children }) => {
  setHook('Router', useRouter);

  const path = usePathname();
  if (!path) {
    throw new InvalidStateError('No path found in the router.');
  }

  const page = PageService.generatePageFromPath(path);

  return (
    <RouterEventProvider>
      <Suspense fallback={null}>
        <RouterEvents />
      </Suspense>
      <TopErrorProvider>
        <TopErrorAlertList />
        <RootErrorBoundary>
          <GlobalErrorAlertProvider>
            <NextTopLoader
              color={headerLoadingBarColor}
              height={parseInt(headerLoadingBarHeight, 10)}
              showSpinner={false}
            />
            <PageProvider page={page}>
              <NotificationsManager>
                <GlobalProvider>
                  <Osano />
                  <Suspense>
                    <GTM />
                  </Suspense>
                  <Forter />
                  <Datadog />
                  <DynamicYield />
                  <SpeedInsights />
                  <ReactHooksWrapper />
                  {children}
                </GlobalProvider>
              </NotificationsManager>
            </PageProvider>
          </GlobalErrorAlertProvider>
        </RootErrorBoundary>
      </TopErrorProvider>
    </RouterEventProvider>
  );
};

export default ClientRoot;
