import { reconstructChildren } from '@/react/utils/children-utils';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import type { ReactNode } from 'react';
import { Default } from '../Default';

import {
  breakpointValues,
  IRawBreakpoint,
  MediaBreakpoint,
  MediaBreakpoints,
  NamedBreakpoint,
  namedBreakpoints,
  RangeBreakpoint,
  rangeBreakpoints,
  SimpleBreakpoint,
  simpleBreakpoints,
  SimpleBreakpoints
} from './breakpoints';

/**
 * Given an array of media breakpoints (simple, range, named)
 * returns an array of simple breakpoints that provide the equivalent
 * breakpoint coverage.
 *
 * @example ```ts
 *  simplifyBreakpoints(['phone', 'desktop']) == ['xxs', 'xs', 'lg', 'xl']
 *  simplifyBreakpoints(['sm-max', 'xl']) == ['xxs', 'xs', 'sm', 'xl']
 * ```
 *
 * @param breakpoints - The array of complex breakpoints.
 * @returns An array of simple breakpoints providing same breakpoint coverage.
 */
export const simplifyBreakpoints = (
  breakpoints: MediaBreakpoints
): SimpleBreakpoints => {
  const simples = new Set<SimpleBreakpoint>();

  breakpoints.forEach((breakpoint) => {
    // For desktop, tablet, phone
    if (breakpoint in namedBreakpoints) {
      const simplified = namedBreakpoints[breakpoint as NamedBreakpoint];
      for (const simple of simplified) {
        simples.add(simple);
      }
      return;
    }

    // If it's a simple breakpoint, without -min or -max
    if (breakpoint.indexOf('-') === -1) {
      simples.add(breakpoint as SimpleBreakpoint);
      return;
    }

    // Range breakpoint
    const simplified =
      rangeBreakpoints.get(breakpoint as RangeBreakpoint) ?? [];
    for (const simple of simplified) {
      simples.add(simple);
    }
  });

  return Array.from(simples);
};

/**
 * Given an array of breakpoints that already exist, returns an array of breakpoints that are missing
 * to achieve full breakpoint coverage.
 * The array given can have named breakpoints or even range breakpoints (xs-min, lg-max).
 *
 * @example ```tsx
 *  determineMissingBreakpoints(['phone', 'tablet']) == ['lg', 'xl']
 *  determineMissingBreakpoints(['lg-max']) == ['xl']
 *  determineMissingBreakpoints(['xxs', 'xs', 'sm', 'xl']) == ['md', 'lg']
 * ```
 *
 * @param existingBreakpoints - Existing breakpoints.
 * @returns Missing breakpoints to have complete coverage.
 */
export const determineMissingBreakpoints = (
  existingBreakpoints: MediaBreakpoints
): MediaBreakpoints => {
  // Simplify (break down) existing breakpoints
  const existing = simplifyBreakpoints(existingBreakpoints);

  // Take all the possible breakpoints and filter out the ones that
  // have been provided.
  return simpleBreakpoints.filter((breakpoint) => {
    return existing.indexOf(breakpoint) === -1;
  });
};

/**
 * Checks if any of the breakpoints match the current window.
 * For SSR, always returns true, since the return value of this function
 * is used to unmount and unrender some parts of the tree, and for SSR we
 * want to render all parts of the tree, and send it to the client for CSS
 * to decide which one to show.
 *
 * @param breakpoints - The breakpoints to check against.
 * @returns Wether any of the breakpoints match the current window size.
 */
export const hasAnyBreakpointMatch = (
  breakpoints: MediaBreakpoints
): boolean => {
  // If we're on the server-side, always return true, so that the server
  // renders "thinks" all of the breakpoints are visible and renders all of them.
  if (!(typeof window !== "undefined")) {
    return true;
  }

  const width = window.innerWidth;

  // If something goes wrong, alwaus return true, this will ensure that
  // every tree gets rendered and CSS will decide which one to show.
  // It's better to use a little bit more memory and have multiple trees
  // than to delete them and mess something up.
  if (!width) {
    return true;
  }

  // Create a mapping between a breakpoint name, and a boolean indicating
  // if that breakpoint is currently active
  const visibilities = new Map<SimpleBreakpoint, boolean>([
    ['xxs', width < breakpointValues.xs],
    ['xs', width >= breakpointValues.xs && width < breakpointValues.sm],
    ['sm', width >= breakpointValues.sm && width < breakpointValues.md],
    ['md', width >= breakpointValues.md && width < breakpointValues.lg],
    ['lg', width >= breakpointValues.lg && width < breakpointValues.xl],
    ['xl', width >= breakpointValues.xl]
  ]);

  // Simplify the breakpoints (translate desktop into lg, xl) and so on.
  // Makes it easier to check if this breakpoint currently matches the window.
  const simpleBreakpoints = simplifyBreakpoints(breakpoints);
  for (const breakpoint of simpleBreakpoints) {
    if (visibilities.get(breakpoint)) {
      return true;
    }
  }

  return false;
};

/**
 * Transforms the React children of a componnent into an array of raw breakpoints.
 * From the children, calculates the missing breakpoints, and if <Default> tag is present
 * creates shows it for the missing breakpoints.
 *
 * The array returned from this hook can be used directly by <Breakpoints> component
 * to calculate the correct classnames and wrap the trees to allow CSS to decide
 * which tree to show.
 *
 * @param children - The children of the <Breakpoints> element.
 * @returns An array of raw breakpoints.
 * @throws If there are missing breakpoints and the <Default> tag is not present.
 * @throws If there are no missing breakpoints but the <Default> tag is still present.
 */
export const getRawBreakpoints = (
  children: ReactNode
): Array<IRawBreakpoint> => {
  const reconstructedChildren = reconstructChildren(children);
  const existingBreakpointsSet = new Set<MediaBreakpoint>();

  // The tree to render in default scenario (when no other breakpoint matches).
  let defaultTree: ReactNode = null;
  const rawBreakpoints: Array<IRawBreakpoint> = [];

  reconstructedChildren.forEach((child) => {
    if (child.type === Default) {
      defaultTree = child.node;
    }

    if (child.props.media) {
      const media = child.props.media as MediaBreakpoint | MediaBreakpoints;
      const breakpoints = media instanceof Array ? media : [media];
      breakpoints.forEach((breakpoint) => {
        existingBreakpointsSet.add(breakpoint);
      });

      rawBreakpoints.push({
        breakpoints,
        tree: child.node
      });
    }
  });

  const existingBreakpoints = Array.from(existingBreakpointsSet);
  /** Determine the missing breakpoints. */
  const missingBreakpoints = determineMissingBreakpoints(existingBreakpoints);
  if (missingBreakpoints.length > 0 && !defaultTree) {
    throw new Error(
      'The <Breakpoints> element with following breakpoints: [' +
        existingBreakpoints.join(', ') +
        '] is missing the following breakpoints: [' +
        missingBreakpoints.join(', ') +
        '] and there is no <Default> breakpoint present. This will yield a gap in breakpoint coverage! ' +
        'Either add a <Breakpoint> with the missing breakpoints or use the <Default> element to fill in the gap'
    );
  }

  // Unused code is terrible!
  if (defaultTree && missingBreakpoints.length === 0) {
    throw new Error(
      'The <Breakpoints> component covers all of the breakpoints using the ' +
        'provided <Breakpoint>-s, however the <Default> tag is present which will never be used'
    );
  }

  // We either have no missing breakpoints, so Default is not necessary
  // or we have missing breakpoints but the Default element is present.
  if (defaultTree && missingBreakpoints.length > 0) {
    rawBreakpoints.push({
      breakpoints: missingBreakpoints,
      tree: defaultTree
    });
  }

  return rawBreakpoints;
};
