import ProductService from '@/services/isomorphic/ProductService';
import { IProduct, ProductModel } from '@/services/models/Product';
import {
  VariationAttributeType,
  type VariationAttributeTypeFromType
} from '@/services/models/Product/variation-attributes';
import { makeAutoObservable, runInAction } from 'mobx';

/**
 * Type to represent color variants in a more readable way.
 */
type ColorVariants = ReadonlyArray<
  VariationAttributeTypeFromType<VariationAttributeType.Color>
>;

/**
 * View model for the product tile component. Will be used to manage the state of the product tile.
 *
 * At first, we are migrating only the color variants.
 */
export default class ProductTileVM {
  // Used to back support old usage of model directly inside ProductTile view.
  // Ideally should be fully abstracted by the view model in the future.
  private _productModel: ProductModel;
  private _colorVariants: ColorVariants;

  /** @inheritdoc */
  public get colorVariants(): ColorVariants {
    return this._colorVariants;
  }

  /** @inheritdoc */
  public get productModel(): ProductModel {
    return this._productModel;
  }

  /**
   * Creates a new instance of the ProductTileVM.
   *
   * @param product - The product model to create the view model from.
   */
  public constructor(private product: IProduct) {
    this._productModel = ProductModel.from(this.product);
    this._colorVariants = this._productModel.getAvailableAttributesByType(
      VariationAttributeType.Color
    );
    makeAutoObservable(this);
  }

  /**
   * Retrieves the product variant by SKU.
   *
   * @param sku - The SKU of the product variant to retrieve.
   * @returns The product variant.
   */
  public async selectProductVariantBySKU(sku: string): Promise<void> {
    const newProductModel = await ProductService.getProduct(sku);

    runInAction(() => {
      this._productModel = newProductModel;
    });
  }
}
