'use client';

import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { FunctionComponent, SVGProps } from 'react';
import { AhnuLogo } from './AhnuLogo';
import { ConductorLogo } from './ConductorLogo';
import { HokaLogo } from './HokaLogo';
import { KoolaburraLogo } from './KoolaburraLogo';
import { SanukLogo } from './SanukLogo';
import { TevaLogo } from './TevaLogo';
import { UggCollabLogo } from './UggCollabLogo';
import { UggLogo } from './UggLogo';

/**
 * Generic brand logo component.
 */
export const Logo: FunctionComponent<SVGProps<SVGSVGElement>> = () => {
  const BrandSpecificLogo = useBrandLocaleValue<FunctionComponent | undefined>(
    () => ({
      default: undefined,
      AHNU: AhnuLogo
    }),
    []
  );

  if (!BrandSpecificLogo) return null;
  return <BrandSpecificLogo />;
};
