'use client';

import { FunctionComponent, useMemo } from 'react';
import SwiperCore, { A11y, Autoplay, EffectFade, Zoom } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useZoomLevel } from '@/react/hooks/useZoomLevel';
import { SliderNavigationButton, SliderPagination } from './components';

import { ISliderOptions, ISliderProps } from './ISliderProps';
import { ISliderContext, SliderContext } from './utils/context';
import { deriveSliderOptionsFromProps, divideChildren } from './utils/helpers';
import { useReactiveSlider } from './utils/hooks';

import 'swiper/swiper-bundle.css';

import S from './styles.base.module.scss';

SwiperCore.use([A11y, Zoom, Autoplay, EffectFade]);

/**
 * Highly customizable Slider component.
 */
export const Slider: FunctionComponent<ISliderProps> = (props) => {
  /** Transform the props into options. */
  const options: ISliderOptions = deriveSliderOptionsFromProps(props);
  const {
    direction,
    height,

    wrapperClassName,
    navigationWrapperClassName,
    sliderClassName,

    navigation,
    pagination,
    autoplay,
    transitionSpeedMS,

    centeredSlides,
    slidesPerView,
    slidesPerGroup = 1,
    spaceBetween,
    allowTouchMove = true,

    loop,
    grabCursor,
    initialSlide,

    effect
  } = options;

  const { isZoomedIn } = useZoomLevel();

  const { slider, onSlider } = useReactiveSlider();
  const context = useMemo<ISliderContext>(() => {
    return {
      slider,
      options
    } as ISliderContext;
  }, [slider, options]);

  const { style = {}, children } = props;
  const { slides, rest } = divideChildren(children);

  const wrapperClasses = [S.wrapper, wrapperClassName];

  // If we're zoomed in, we want to disable interaction with the slider. To this end, we
  // disable all pointer events on the wrapper element.
  if (isZoomedIn) {
    wrapperClasses.push(S.interactionDisabled);
  }

  const wrapperClassesString = wrapperClasses.join(' ');

  const navigationWrapperClasses = [
    S.navigationWrapper,
    navigationWrapperClassName
  ];

  const navigationWrapperClassesString = navigationWrapperClasses.join(' ');

  // Wrapper direction should reflect the direction chosen for
  // the pagination component, and accommodate for that.  Ie. a vertical
  // pagination would need horizontal wrapper layout, and vice versa.
  // Note that 'vertical' is assigned to the wrapper direction if no
  // explicit pagination direction is set.
  const wrapperDirection =
    pagination.direction === 'vertical' ? 'horizontal' : 'vertical';

  // Only render the pagination and navigation components if the slider instance
  // has been retrieved from the Swiper component,
  // since the nested components depend on the slider to be available in the context tree
  // and the slider state is finalized after initialization.
  return (
    <SliderContext.Provider value={context}>
      <div className={wrapperClassesString} data-direction={wrapperDirection}>
        <div
          className={navigationWrapperClassesString}
          data-direction={direction}
        >
          {slider && navigation.enabled && (
            <SliderNavigationButton navigateTo="previous" />
          )}
          <Swiper
            onSwiper={onSlider}
            direction={direction}
            style={{
              height,
              ...style
            }}
            className={S.swiper + ' ' + sliderClassName}
            centeredSlides={centeredSlides}
            slidesPerView={slidesPerView}
            slidesPerGroup={slidesPerGroup}
            spaceBetween={spaceBetween}
            initialSlide={initialSlide ?? 0}
            loop={loop}
            grabCursor={grabCursor}
            effect={effect}
            speed={transitionSpeedMS}
            allowTouchMove={!isZoomedIn && allowTouchMove}
            allowSlideNext={!isZoomedIn}
            allowSlidePrev={!isZoomedIn}
            autoplay={
              autoplay.enabled && {
                delay: autoplay.delay,
                disableOnInteraction: autoplay.disableOnInteraction,
                reverseDirection: autoplay.reverse,
                stopOnLastSlide: !autoplay.loop
              }
            }
            zoom={false}
          >
            {/* Render slides immediately to get the correct measurements and slide count. */}
            {slides}

            {/* Render custom nested components after context tree is populated,
            to avoid null checks in children. */}
            {slider && rest}
          </Swiper>
          {slider && !isZoomedIn && navigation.enabled && (
            <SliderNavigationButton navigateTo="next" />
          )}
        </div>
        {slider && !isZoomedIn && pagination.enabled && <SliderPagination />}
      </div>
    </SliderContext.Provider>
  );
};

export { SwiperSlide as Slide };

export * from './components';
export * from './utils/hooks';

export type { ISliderOptions, ISliderProps };
