import type { FC, PropsWithChildren } from 'react';

/**
 * Tells the <Breakpoints> component the default tree that should be rendered
 * when none of the other breakpoints are matched.
 *
 * The component itself doesn't implement any logic, it's main use is for
 * developer usability.
 */
export const Default: FC<PropsWithChildren> = ({ children }) => {
  return <>{children}</>;
};
