import type { ICart } from '../../models/Cart';
import type { ILineItem, IProductLineItem } from '../../models/Cart/LineItem';
import type { IPlacedOrder } from '../../models/Order';
import type { ICoveoSearchData, IPage } from '../../models/Page';
import type { IProduct } from '../../models/Product';

/**
 * Full list of possible events for the InteractionData.
 * These are generic, but mostly correspond to existing GTM events.
 */
export enum EventType {
  PageView = 'page_view',
  NavigationLink = 'navigation:link',
  ProductView = 'product:view',
  ProductAdd = 'product:add',
  ProductRemove = 'product:remove',
  ProductSizeChart = 'product:sizeChart',
  ProductUpdate = 'product:updateAddToCart',
  ProductUpdateStyle = 'product:updateStyle',
  ProductUpdateVariation = 'product:updateVariation',
  QuickviewShow = 'quickview:show',
  SearchShowMore = 'search:showMore',
  SearchSort = 'search:sort',
  TileClick = 'tile:click',
  SearchComplete = 'search:complete',
  // Checkout
  ShippingStart = 'checkout:shippingStart',
  ShippingSubmit = 'checkout:shippingSubmit',
  ShippingError = 'checkout:shippingError',
  ShippingSuccess = 'checkout:shippingSuccess',

  BillingStart = 'checkout:billingStart',
  BillingSubmit = 'checkout:billingSubmit',
  BillingError = 'checkout:billingError',
  BillingSuccess = 'checkout:billingSuccess',

  ClearBillingForm = 'checkout:clearBillingForm',

  OrderPlace = 'order:place',
  OrderError = 'order:error',
  OrderSuccess = 'order:success',

  UserSignout = 'user:signout',
  LoginRegister = 'login:register',
  LoginError = 'login:error',
  LoginSubmit = 'login:submit',
  LoginSuccess = 'login:success',
  PasswordEdit = 'password:edit',
  ProfileEdit = 'profile:edit',
  SignupError = 'signup:error',
  SignupSuccess = 'signup:success',
  FormInput = 'form:input',
  FormSubmit = 'form:submit',

  Scroll = 'scroll'
}

/**
 * The interaction link is supplied to the UserInteractionService
 * in the 'navigation:link' event.
 */
export interface IInteractionLink {
  /** The link label, the display text. */
  linkName: string;
  /** The place of the link on the page, for instance the top-navigation. */
  linkCategory: 'top-navigation';
  /** The parent category of the given link. */
  linkSection: string;
}

/**
 * The page view event, it requires a page to construct a PageModel with
 * and optionally it can take a product list to represent all the products
 * on the page on initial load.
 */
export interface IInteractionPageDetails {
  /** @inheritdoc */
  action: EventType.PageView | EventType.ProductUpdateStyle;

  /** The page which holds various meta-data. */
  page: IPage;

  /** A products list for knowing which products are on which pages. */
  products?: Array<IProduct>;

  /** Similar to the product list but using line items instead. */
  lines?: Array<IProductLineItem>;

  /** The placed order on the confirmation page. */
  order?: IPlacedOrder;
}

/**
 * The Product interaction event requires
 * a product and is used to send GTM and Coveo
 * data about a product.
 */
export interface IInteractionProductDetails {
  /** @inheritdoc */
  action:
    | EventType.ProductAdd
    | EventType.TileClick
    | EventType.ProductUpdateVariation;

  /** The product, used to construct a product model in order to distribute data. */
  product: IProduct;
}

/**
 * The line item event sends line item data when there
 * are recorded interactions with the line item.
 */
export interface IInteractionLineItemDetails {
  /** @inheritdoc */
  action: EventType.ProductRemove;

  /** The standard line item used to distribute data. */
  lineItem: ILineItem;
}

/**
 * The link interaction holds a link object that
 * holds meta data about the link that has been
 * interacted with.
 */
export interface IInteractionLinkDetails {
  /** @inheritdoc */
  action: EventType.NavigationLink;
  /** A custom link object used for representing the header link in interactions. */
  link: IInteractionLink;
}

/**
 * The interaction search details holds information
 * about a product list service response.
 */
export interface IInteractionSearchDetails {
  /** @inheritdoc */
  action: EventType.SearchComplete;

  /** The search data maintained to provide information in an analytics event. */
  searchData: ICoveoSearchData;
}

/**
 * Interaction details with order data.
 */
export interface IInteractionOrderDetails {
  /** @inheritdoc */
  action: EventType.OrderSuccess;
  /** The order data for the successful order. */
  orderData: IPlacedOrder;
  /**
   * Holds the cart right before the place order events occur. This way the data is accessible after
   * clearing the cart and replacing it with a new one.
   */
  cartData: ICart;
}

/**
 * The interaction form is supplied to the UserInteractionService
 * in the 'form:input' event.
 */
export interface IInteractionForm {
  /** @inheritdoc */
  action: EventType.FormInput | EventType.FormSubmit;
  /** The form name. */
  formName: string;
  /** The form field name. */
  formFieldName?: string;
}

/**
 * The interaction customer is supplied to the UserInteractionService
 * in the 'form:input' event.
 */
export interface IInteractionCustomer {
  /** @inheritdoc */
  action: EventType.SignupSuccess;
  /** The encrypted customer email. */
  customer_email: string;
}

/**
 * The interaction containing the information needed
 * to distribute to various event records. It contains an
 * action string and some data.
 *
 * The interaction data is a container for supplying data to the
 * UserInteractionService. It is meant to represent the data in our application
 * and should not be preformatted for a specific use case: eg. GTM.
 */
export interface IInteractionDetails {
  /**
   * The action enum, which represents the event type.
   * The event type can be thought of as represented a specific type
   * of user interaction.
   */
  action:
    | EventType.QuickviewShow
    | EventType.ProductUpdate
    | EventType.SearchSort
    | EventType.SearchShowMore
    | EventType.ProductSizeChart
    | EventType.ProductView
    // Checkout
    | EventType.ShippingStart
    | EventType.ShippingSubmit
    | EventType.ShippingSuccess
    | EventType.ShippingError
    | EventType.BillingStart
    | EventType.BillingSubmit
    | EventType.BillingSuccess
    | EventType.BillingError
    | EventType.ClearBillingForm
    | EventType.OrderPlace
    | EventType.OrderError
    | EventType.LoginRegister
    | EventType.LoginError
    | EventType.LoginSubmit
    | EventType.LoginSuccess
    | EventType.UserSignout
    | EventType.PasswordEdit
    | EventType.ProfileEdit
    | EventType.SignupError;
}

/**
 * The interaction scroll event is used to track the scroll position of the user
 * on any page in the application.
 */
export interface IInteractionScroll {
  /** @inheritdoc */
  action: EventType.Scroll;
  /** The scroll position of the user on the home page. */
  scrollPositionPercentage: number;
  /** The page which holds various meta-data. */
  page: IPage;
  /**
   * The device type of the user, this is used to determine if the user is on
   * a mobile or desktop device.
   */
  device: 'mobile' | 'desktop';
}

/**
 * This union type is used the full collection of possible interactions.
 */
export type InteractionDetails =
  | IInteractionDetails
  | IInteractionSearchDetails
  | IInteractionLineItemDetails
  | IInteractionProductDetails
  | IInteractionLinkDetails
  | IInteractionPageDetails
  | IInteractionOrderDetails
  | IInteractionForm
  | IInteractionCustomer
  | IInteractionScroll;
