// Solid
import Image from 'next/image';

import User from './svgs/solid/user.svg';
import Bars from './svgs/solid/bars.svg';
import CartShopping from './svgs/solid/cart-shopping.svg';
import Minus from './svgs/solid/minus.svg';
import Plus from './svgs/solid/plus.svg';
import Ruler from './svgs/solid/ruler.svg';
import Xmark from './svgs/solid/xmark.svg';
import SolidHeart from './svgs/solid/heart.svg';
import Lock from './svgs/solid/lock.svg';
import CommentLines from './svgs/solid/comment-lines.svg';
import CirclePlus from './svgs/solid/circle-plus.svg';
import CircleNotch from './svgs/solid/circle-notch.svg';
import FullStar from './svgs/solid/star.svg';
import HalfStar from './svgs/solid/star-half.svg';
import ChevronUp from './svgs/solid/chevron-up.svg';
import ChevronDown from './svgs/solid/chevron-down.svg';

// Solid Directions
import ArrowUp from './svgs/solid/arrow-up.svg';
import ArrowDown from './svgs/solid/arrow-down.svg';
import ArrowLeft from './svgs/solid/arrow-left.svg';
import ArrowRight from './svgs/solid/arrow-right.svg';
import AngleLeft from './svgs/solid/angle-left.svg';
import AngleRight from './svgs/solid/angle-right.svg';
import AngleUp from './svgs/solid/angle-up.svg';
import AngleDown from './svgs/solid/angle-down.svg';
import CaretUp from './svgs/solid/caret-up.svg';
import CaretDown from './svgs/solid/caret-down.svg';
import CaretLeft from './svgs/solid/caret-left.svg';
import CaretRight from './svgs/solid/caret-right.svg';
import RightLeft from './svgs/solid/right-left.svg';
import MapMarker from './svgs/solid/map-marker.svg';

// Regular
import BarChart from './svgs/regular/bar-chart.svg';
import Briefcase from './svgs/regular/briefcase.svg';
import ChatboxEllipses from './svgs/regular/chatbox-ellipses.svg';
import EmptyStar from './svgs/regular/star.svg';
import ExclamationCircle from './svgs/regular/circle-exclamation.svg';
import Expand from './svgs/regular/expand.svg';
import Heart from './svgs/regular/heart.svg';
import Megaphone from './svgs/regular/megaphone.svg';
import Paper from './svgs/regular/paper.svg';
import Reload from './svgs/regular/reload.svg';
import Terminal from './svgs/regular/terminal.svg';
import TrashCan from './svgs/regular/trash-can.svg';
import ThumbsUp from './svgs/regular/thumbs-up.svg';
import ThumbsDown from './svgs/regular/thumbs-down.svg';
import QuestionCircle from './svgs/regular/circle-question.svg';
import UserOutline from './svgs/regular/user.svg';
import Storefront from './svgs/regular/storefront.svg';
import FileTray from './svgs/regular/file-tray.svg';
import BoundingBox from './svgs/regular/bounding-box.svg';

// Regular Sharp
import EmptyStarSharp from './svgs/regular/sharp/star.svg';

// Solid
import Eye from './svgs/solid/eye.svg';
import EyeSlash from './svgs/solid/eye-slash.svg';
import Loader from './svgs/solid/loader.svg';
import LockKeyhole from './svgs/solid/lock-keyhole.svg';
import MagnifyingGlass from './svgs/solid/magnifying-glass.svg';
import MagnifyingGlassPlus from './svgs/solid/magnifying-glass-plus.svg';
import MagnifyingGlassMinus from './svgs/solid/magnifying-glass-minus.svg';

// Sharp Solid
import Play from './svgs/sharp-solid/play.svg';
import Pause from './svgs/sharp-solid/pause.svg';
import StarSharp from './svgs/sharp-solid/star.svg';
import HalfStarSharp from './svgs/sharp-solid/half-star.svg';

// Light
import SearchLight from './svgs/light/magnifying-glass.svg';
import BagShoppingLight from './svgs/light/bag-shopping.svg';
import UserLight from './svgs/light/user.svg';
import BarsLight from './svgs/light/bars.svg';
import XmarkLight from './svgs/light/xmark.svg';
import CircleNotchLight from './svgs/light/circle-notch.svg';
import AngleLeftLight from './svgs/light/angle-left.svg';
import AngleRightLight from './svgs/light/angle-right.svg';
import AngleUpLight from './svgs/light/angle-up.svg';
import AngleDownLight from './svgs/light/angle-down.svg';
import ArrowRightLight from './svgs/light/arrow-right.svg';
import ChevronUpLight from './svgs/light/chevron-up.svg';
import ChevronDownLight from './svgs/light/chevron-down.svg';
import ExpandLight from './svgs/light/expand.svg';
import CircleChevronRightLight from './svgs/light/circle-chevron-right.svg';
import CircleChevronLeftLight from './svgs/light/circle-chevron-left.svg';
import ThumbsUpLight from './svgs/light/thumbs-up.svg';
import ThumbsDownLight from './svgs/light/thumbs-down.svg';

// Brands
import FacebookF from './svgs/brands/facebook-f.svg';
import PinterestP from './svgs/brands/pinterest-p.svg';
import Youtube from './svgs/brands/youtube.svg';
import Twitter from './svgs/brands/twitter.svg';
import Instagram from './svgs/brands/instagram.svg';
import Strava from './svgs/brands/strava.svg';
import TikTok from './svgs/brands/tiktok.svg';

// Flags
import AUFlag from './svgs/flags/au.svg';
import BEFlag from './svgs/flags/be.svg';
import BGFlag from './svgs/flags/bg.svg';
import CZFlag from './svgs/flags/cz.svg';
import DEFlag from './svgs/flags/de.svg';
import DKFlag from './svgs/flags/dk.svg';
import EEFlag from './svgs/flags/ee.svg';
import ESFlag from './svgs/flags/es.svg';
import FIFlag from './svgs/flags/fi.svg';
import FRFlag from './svgs/flags/fr.svg';
import GBFlag from './svgs/flags/gb.svg';
import GRFlag from './svgs/flags/gr.svg';
import HKFlag from './svgs/flags/hk.svg';
import HRFlag from './svgs/flags/hr.svg';
import HUFlag from './svgs/flags/hu.svg';
import IEFlag from './svgs/flags/ie.svg';
import JPFlag from './svgs/flags/jp.svg';
import LTFlag from './svgs/flags/lt.svg';
import LUFlag from './svgs/flags/lu.svg';
import NLFlag from './svgs/flags/nl.svg';
import NZFlag from './svgs/flags/nz.svg';
import PLFlag from './svgs/flags/pl.svg';
import PTFlag from './svgs/flags/pt.svg';
import ROFlag from './svgs/flags/ro.svg';
import SEFlag from './svgs/flags/se.svg';
import SIFlag from './svgs/flags/si.svg';
import SKFlag from './svgs/flags/sk.svg';
import USFlag from './svgs/flags/us.svg';

import blog from './pngs/blog.png';

import deckersWhite from './pngs/deckers-white.png';
import deckersBlack from './pngs/deckers-black.png';

/**
 * A list of all available icons and their access name and the string used
 * to get the actual icon.
 */
export enum IconTypes {
  // Core
  Close = 'close',
  CloseLight = 'closeLight',
  Bars = 'bars',
  BarsLight = 'barsLight',
  Search = 'search',
  SearchLight = 'searchLight',
  User = 'user',
  UserLight = 'userLight',
  UserOutline = 'userOutline',
  Ruler = 'ruler',
  Minus = 'minus',
  CirclePlus = 'circlePlus',
  Plus = 'plus',
  ChevronUp = 'chevronUp',
  ChevronUpLight = 'chevronUpLight',
  ChevronDown = 'chevronDown',
  ChevronDownLight = 'chevronDownLight',
  Cart = 'cart',
  BagLight = 'bagLight',
  Heart = 'heart',
  HeartSolid = 'heartSolid',
  CircleNotch = 'circleNotch',
  CircleNotchLight = 'circleNotchLight',
  CircleChevronRightLight = 'circleChevronRightLight',
  CircleChevronLeftLight = 'circleChevronLeftLight',
  EmptyStar = 'emptyStar',
  HalfStar = 'halfStar',
  FullStar = 'fullStar',
  FullStarSharp = 'fullStarSharp',
  EmptyStarSharp = 'emptyStarSharp',
  HalfStarSharp = 'halfStarSharp',
  FullScreen = 'fullScreen',
  FullScreenLight = 'fullScreenLight',
  Trash = 'trash',
  Comment = 'comment',
  Lock = 'lock',
  Alert = 'alert',
  Like = 'like',
  LikeLight = 'likeLight',
  Dislike = 'dislike',
  DislikeLight = 'dislikeLight',
  MagnifyingGlass = 'magnifyingGlass',
  MagnifyingGlassPlus = 'magnifyingGlassPlus',
  MagnifyingGlassMinus = 'magnifyingGlassMinus',
  Eye = 'eye',
  EyeSlash = 'eyeSlash',
  LockKeyHole = 'lockKeyhole',

  // Deckers
  DeckersWhite = 'deckersWhite',
  DeckersBlack = 'deckersBlack',

  // Directions
  ArrowUp = 'arrowUp',
  ArrowDown = 'arrowDown',
  ArrowLeft = 'arrowLeft',
  ArrowRight = 'arrowRight',
  ArrowRightLight = 'arrowRightLight',
  AngleRight = 'angleRight',
  AngleLeft = 'angleLeft',
  AngleUp = 'angleUp',
  AngleDown = 'angleDown',
  AngleRightLight = 'angleRightLight',
  AngleLeftLight = 'angleLeftLight',
  AngleUpLight = 'angleUpLight',
  AngleDownLight = 'angleDownLight',
  CaretUp = 'caretUp',
  CaretRight = 'caretRight',
  CaretDown = 'caretDown',
  CaretLeft = 'caretLeft',
  Pause = 'pause',
  Play = 'play',
  Loader = 'loader',
  RightLeft = 'rightLeft',
  MapMarker = 'mapMarker',

  // Social
  Instagram = 'instagram',
  Twitter = 'twitter',
  YouTube = 'youtube',
  Pinterest = 'pinterest',
  Facebook = 'facebook',
  QuestionCircle = 'questionCircle',
  Blog = 'blog',
  Strava = 'strava',
  TikTok = 'tiktok',

  // Flags
  AUFlag = 'auFlag',
  BEFlag = 'beFlag',
  BGFlag = 'bgFlag',
  CZFlag = 'czFlag',
  DEFlag = 'deFlag',
  DKFlag = 'dkFlag',
  EEFlag = 'eeFlag',
  ESFlag = 'esFlag',
  FIFlag = 'fiFlag',
  FRFlag = 'frFlag',
  GBFlag = 'gbFlag',
  GRFlag = 'grFlag',
  HKFlag = 'hkFlag',
  HRFlag = 'hrFlag',
  HUFlag = 'huFlag',
  IEFlag = 'ieFlag',
  JPFlag = 'jpFlag',
  LTFlag = 'ltFlag',
  LUFlag = 'luFlag',
  NLFlag = 'nlFlag',
  NZFlag = 'nzFlag',
  PLFlag = 'plFlag',
  PTFlag = 'ptFlag',
  ROFlag = 'roFlag',
  SEFlag = 'seFlag',
  SIFlag = 'siFlag',
  SKFlag = 'skFlag',
  USFlag = 'usFlag',

  // Business
  BarChart = 'barChart',
  Briefcase = 'briefcase',
  ChatboxEllipses = 'chatboxEllipses',
  Megaphone = 'megaphone',
  Paper = 'paper',
  Reload = 'reload',
  Terminal = 'terminal',
  Storefront = 'storefront',
  FileTray = 'fileTray',
  BoundingBox = 'boundingBox'
}

/**
 * Object used to turn the icon list into the specific icon imports.
 */
export const icons: Record<IconTypes, JSX.Element> = {
  // Core
  close: (() => <Xmark />)(),
  closeLight: (() => <XmarkLight />)(),
  bars: (() => <Bars />)(),
  barsLight: (() => <BarsLight />)(),
  search: (() => <SearchLight />)(),
  searchLight: (() => <SearchLight />)(),
  user: (() => <User />)(),
  userLight: (() => <UserLight />)(),
  userOutline: (() => <UserOutline />)(),
  ruler: (() => <Ruler />)(),
  minus: (() => <Minus />)(),
  plus: (() => <Plus />)(),
  circlePlus: (() => <CirclePlus />)(),
  circleChevronLeftLight: (() => <CircleChevronLeftLight />)(),
  circleChevronRightLight: (() => <CircleChevronRightLight />)(),
  chevronUp: (() => <ChevronUp />)(),
  chevronUpLight: (() => <ChevronUpLight />)(),
  chevronDown: (() => <ChevronDown />)(),
  chevronDownLight: (() => <ChevronDownLight />)(),
  cart: (() => <CartShopping />)(),
  bagLight: (() => <BagShoppingLight />)(),
  heartSolid: (() => <SolidHeart />)(),
  heart: (() => <Heart />)(),
  circleNotch: (() => <CircleNotch />)(),
  circleNotchLight: (() => <CircleNotchLight />)(),
  lock: (() => <Lock />)(),
  emptyStar: (() => <EmptyStar />)(),
  halfStar: (() => <HalfStar />)(),
  fullStar: (() => <FullStar />)(),
  fullScreen: (() => <Expand />)(),
  fullStarSharp: (() => <StarSharp />)(),
  emptyStarSharp: (() => <EmptyStarSharp />)(),
  halfStarSharp: (() => <HalfStarSharp />)(),
  fullScreenLight: (() => <ExpandLight />)(),
  trash: (() => <TrashCan />)(),
  alert: (() => <ExclamationCircle />)(),

  comment: (() => <CommentLines />)(),
  play: (() => <Play />)(),
  pause: (() => <Pause />)(),
  loader: (() => <Loader />)(),
  like: (() => <ThumbsUp />)(),
  likeLight: (() => <ThumbsUpLight />)(),
  dislike: (() => <ThumbsDown />)(),
  dislikeLight: (() => <ThumbsDownLight />)(),
  magnifyingGlass: (() => <MagnifyingGlass />)(),
  magnifyingGlassPlus: (() => <MagnifyingGlassPlus />)(),
  magnifyingGlassMinus: (() => <MagnifyingGlassMinus />)(),
  eye: (() => <Eye />)(),
  eyeSlash: (() => <EyeSlash />)(),
  lockKeyhole: (() => <LockKeyhole />)(),

  // Direction
  arrowUp: (() => <ArrowUp />)(),
  arrowRight: (() => <ArrowRight />)(),
  arrowRightLight: (() => <ArrowRightLight />)(),
  arrowDown: (() => <ArrowDown />)(),
  arrowLeft: (() => <ArrowLeft />)(),
  angleRight: (() => <AngleRight />)(),
  angleLeft: (() => <AngleLeft />)(),
  angleUp: (() => <AngleUp />)(),
  angleDown: (() => <AngleDown />)(),
  angleRightLight: (() => <AngleRightLight />)(),
  angleLeftLight: (() => <AngleLeftLight />)(),
  angleUpLight: (() => <AngleUpLight />)(),
  angleDownLight: (() => <AngleDownLight />)(),
  caretUp: (() => <CaretUp />)(),
  caretRight: (() => <CaretRight />)(),
  caretDown: (() => <CaretDown />)(),
  caretLeft: (() => <CaretLeft />)(),
  rightLeft: (() => <RightLeft />)(),
  mapMarker: (() => <MapMarker />)(),

  // Socials
  instagram: (() => <Instagram />)(),
  twitter: (() => <Twitter />)(),
  youtube: (() => <Youtube />)(),
  pinterest: (() => <PinterestP />)(),
  facebook: (() => <FacebookF />)(),
  questionCircle: (() => <QuestionCircle />)(),
  blog: (() => <Image alt="blog" src={blog} width={52} />)(),
  strava: (() => <Strava />)(),
  tiktok: (() => <TikTok />)(),

  // Flags
  auFlag: (() => <AUFlag />)(),
  beFlag: (() => <BEFlag />)(),
  bgFlag: (() => <BGFlag />)(),
  czFlag: (() => <CZFlag />)(),
  deFlag: (() => <DEFlag />)(),
  dkFlag: (() => <DKFlag />)(),
  eeFlag: (() => <EEFlag />)(),
  esFlag: (() => <ESFlag />)(),
  fiFlag: (() => <FIFlag />)(),
  frFlag: (() => <FRFlag />)(),
  gbFlag: (() => <GBFlag />)(),
  grFlag: (() => <GRFlag />)(),
  hkFlag: (() => <HKFlag />)(),
  hrFlag: (() => <HRFlag />)(),
  huFlag: (() => <HUFlag />)(),
  ieFlag: (() => <IEFlag />)(),
  jpFlag: (() => <JPFlag />)(),
  ltFlag: (() => <LTFlag />)(),
  luFlag: (() => <LUFlag />)(),
  nlFlag: (() => <NLFlag />)(),
  nzFlag: (() => <NZFlag />)(),
  plFlag: (() => <PLFlag />)(),
  ptFlag: (() => <PTFlag />)(),
  roFlag: (() => <ROFlag />)(),
  seFlag: (() => <SEFlag />)(),
  siFlag: (() => <SIFlag />)(),
  skFlag: (() => <SKFlag />)(),
  usFlag: (() => <USFlag />)(),

  // Business
  barChart: (() => <BarChart />)(),
  briefcase: (() => <Briefcase />)(),
  chatboxEllipses: (() => <ChatboxEllipses />)(),
  megaphone: (() => <Megaphone />)(),
  paper: (() => <Paper />)(),
  reload: (() => <Reload />)(),
  terminal: (() => <Terminal />)(),
  storefront: (() => <Storefront />)(),
  fileTray: (() => <FileTray />)(),
  boundingBox: (() => <BoundingBox />)(),

  // Deckers Logo
  deckersWhite: (() => (
    <Image alt="deckers" src={deckersWhite} width={150} />
  ))(),
  deckersBlack: (() => <Image alt="deckers" src={deckersBlack} width={150} />)()
};
