import type { FC } from 'react';
import type { IBreakpointProps } from '.';

/**
 * Renders the children only for the given media breakpoint.
 * The component itself doesn't implement any logic, but is picked up
 * by the <Breakpoints> component.
 */
export const Breakpoint: FC<IBreakpointProps> = ({ media, children }) => {
  return <>{children}</>;
};
