import config from './config';

/**
 * Describes the valid values for the `resolveTo` configuration.
 * - `smart`: Get the PDP for a platform if the route matches a platform.
 * Otherwise, get the PDP for a product.
 * - `product`: Always resolve to the PDP for the product.
 * Fail if the route does not match a product.
 * - `platform`: Always resolve to the PDP for the platform.
 * If encountering a product, try to resolve to the product's platform.
 * If the platform cannot be resolved, the request should fail.
 */
export type RoutingResolveTo = 'smart' | 'product' | 'platform';

const product = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** On the PDP should the carousel be shown within the variation panel. */
      isCarouselShownWithinVariationsPanelOnMobile: false,
      /**
       * Displays content in and alternate PDP location and not among the accordians.
       */
      displayGenericContent: true,

      /**
       * Product info (ie. description, features, etc.) is typically rendered
       * out via this component, after the Product Details content. We can
       * instead pass the product info to the ProductDetailsPanel component,
       * so that it can be displayed within an alternate component context.
       */
      displayProductDetails: true,

      /**
       * Show the short description on the PDP.
       */
      showShortDescription: false,

      /** Quickview settings. */
      quickView: {
        /**
         * Controls whether the quickview button should show
         * on product tiles.
         */
        show: true
      },

      swatches: {
        /** Enables image swatches on the PDP. */
        enableImageSwatchesPDP: true,
        /** Enable swatch slider on mobile for the PDP. */
        enableMobileSwatchSlider: true
      },

      /** Info sections that should be displayed on the PDPs. */
      details: {
        /** Enables the Description accordion section. */
        descriptionLong: {
          /** Whether the section is enabled. */
          enabled: true,
          /** Whether the section is expanded by default. */
          expanded: true
        },
        /** Enables the Features & Benefits section. */
        featuresAndBenefits: {
          /** Whether the section is enabled. */
          enabled: false,
          /** Whether the section is expanded by default. */
          expanded: false
        },
        /** Enables the Size & Fit section. */
        sizeAndFit: {
          /** Whether the section is enabled. */
          enabled: false,
          /** Whether the section is expanded by default. */
          expanded: false
        },
        /** Enables the Responsibility section. */
        responsibility: {
          /** Whether the section is enabled. */
          enabled: false,
          /** Whether the section is expanded by default. */
          expanded: false
        },
        /** Enables the Care & Cleaning section. */
        care: {
          /** Whether the section is enabled. */
          enabled: false,
          /** Whether the section is expanded by default. */
          expanded: false
        },
        descriptionShort: {
          /** Whether the section is enabled. */
          enabled: false
        },

        /**
         * Settings related to gender adaptive display.
         * @see `GenderAdaptiveProductVM`
         */
        genderAdaptiveDisplay: {
          /**
           * If enabled, PDPs will display a gender selector for models with
           * all-gender sizing. The sizes displayed on the PDP will adapt to the
           * selected gender.
           */
          enabled: true,

          /** If gender adaptive display should be used on quickview as well. */
          useOnQuickview: false,

          /**
           * The gender that will be selected by default on pages using
           * gender adaptive display.
           */
          defaultGenderSelection: 'men'
        }
      },

      /** Lifestyle images settings. */
      lifestyleImages: {
        /**
         * Controls whether lifestyles images (if present) should
         * be displayed on the PDP.
         */
        show: false
      },

      wishlist: {
        /** Enables all wishlist functionality. */
        enable: false
      },

      /** Product parsing settings. */
      parsing: {
        images: {
          /**
           * Maximum time (in milliseconds) an aspect ratio get
           * can take before timing out.
           */
          aspectRatioCalculationTimeout: 5000,

          /**
           * The default aspect ratio to include for product images.
           * This value will be used if the aspect ratio get fails.
           *
           * The current value of `1.2629931120851596` was determined
           * considering that most (if not all) of the product images
           * in the CDN have a size of `2000 x 1597`. This is roughly
           * equal to a **5:4 aspect ratio**.
           */
          defaultAspectRatio: 1.2629931120851596
        }
      },

      plp: {
        /**
         * Determines if it will use the quick on hover color selector or the selection one when
         * displaying a ProductTile in the PLP.
         */
        useQuickColorSelector: true
      },

      /** Routing settings. */
      routing: {
        /** PDP specific routing settings. */
        pdp: {
          /**
           * Determines how to resolve a PDP route (`/p/*`).
           * @see {@link RoutingResolveTo} for possible values and their behavior.
           */
          resolveTo: 'smart' as RoutingResolveTo,

          /**
           * The TTL (in seconds) to use for caching the PDP on the client upon success.
           *
           * It is recommended to set this TTL to be shorter than the CDN TTL,
           * since the CDN cache can be manually invalidated sooner than the client cache.
           */
          clientTTL: 60 * 60, // 1 hour in seconds

          /**
           * The TTL (in seconds) to use for caching the PDP on a CDN upon success.
           *
           * It is recommended to set this TTL both short enough that the
           * inventory data included in cached PDPs doesn't get too old, but long
           * enough that the perceived performance is not greatly impacted.
           */
          cdnTTL: 24 * 60 * 60, // 24 hours (1 day) in seconds

          /**
           * The TTL (in seconds) to use upon failure when routing to the PDP.
           * This is shared by the client and the CDN.
           *
           * It is recommended that this TTL is quite short so that a fluke error
           * doesn't persist for too long. However, we still want to cache the
           * error for a short time to prevent a flood of requests.
           */
          failureTTL: 5 // 5 seconds
        }
      },

      /** Categories to show in the size chart. */
      sizeChartCategoriesToShow: {
        men: true,
        women: true,
        unisex: true,
        youth: true,
        kids: true,
        infants: true,
        toddlers: true,
        littleKids: true,
        bigKids: true
      },
      unisexSizingMeasurements: {
        us: false,
        unisexMen: true,
        unisexWomen: true,
        heelToe: true,
        uk: true,
        eu: true,
        jp: true,
        mx: true,
        cn: true
      }
    }
  },
  'SANUK-US': {
    default: {
      routing: {
        pdp: {
          resolveTo: 'product' as RoutingResolveTo
        }
      }
    }
  },
  'AHNU-US': {
    default: {
      /** Displays carousel within panel on mobile. */
      isCarouselShownWithinVariationsPanelOnMobile: true,
      /** Display generic pdp content. */
      displayGenericContent: false,
      /** Show the short description. */
      showShortDescription: true,
      /** Display the product details in the main pdp section. */
      displayProductDetails: false,
      /** Info sections that should be displayed on the PDPs. */
      details: {
        /** Enables the Description accordion section. */
        descriptionLong: {
          /** Whether the section is enabled. */
          enabled: true,
          /** Whether the section is expanded by default. */
          expanded: false
        },
        /** Enables the Features & Benefits section. */
        featuresAndBenefits: {
          /** Whether the section is enabled. */
          enabled: true,
          /** Whether the section is expanded by default. */
          expanded: false
        },
        /** Enables the Size & Fit section. */
        sizeAndFit: {
          /** Whether the section is enabled. */
          enabled: true,
          /** Whether the section is expanded by default. */
          expanded: false
        },
        /** Enables the Responsibility section. */
        responsibility: {
          /** Whether the section is enabled. */
          enabled: true,
          /** Whether the section is expanded by default. */
          expanded: false
        },
        /** Enables the Care & Cleaning section. */
        care: {
          /** Whether the section is enabled. */
          enabled: false,
          /** Whether the section is expanded by default. */
          expanded: false
        },
        descriptionShort: {
          /** Whether the section is enabled. */
          enabled: true
        }
      },
      /** Lifestyle images settings. */
      lifestyleImages: {
        show: true
      },
      /** Categories to show in the size chart. */
      sizeChartCategoriesToShow: {
        men: true,
        women: true,
        unisex: false,
        youth: false,
        kids: false,
        infants: false,
        toddlers: false,
        littleKids: false,
        bigKids: false
      }
    }
  },
  'TEVA-US': {
    default: {
      plp: {
        /**
         * Determines if it will use the quick on hover color selector or the selection one when
         * displaying a ProductTile in the PLP.
         */
        useQuickColorSelector: false
      },
      /** Categories to show in the size chart. */
      sizeChartCategoriesToShow: {
        men: true,
        women: true,
        unisex: true,
        youth: false,
        kids: false,
        infants: true,
        toddlers: true,
        littleKids: true,
        bigKids: true
      }
    }
  }
});

export default product;
