'use client';

import {
  FC,
  PropsWithChildren,
  useSyncExternalStore,
  type ReactNode
} from 'react';

/**
 * Empty subscribe function.
 * @returns An empty function.
 */
const emptySubscribe = () => () => {};

/**
 * Skip rendering on the server.
 * @param props - The component props.
 * @returns The component children on the client, and `null` on the server.
 */
export const SkipRenderOnServer: FC<
  PropsWithChildren<{ fallback?: ReactNode }>
> = ({ children, fallback }) => {
  /**
   * Whether this component is rendering on the server.
   * @see {@link https://tkdodo.eu/blog/avoiding-hydration-mismatches-with-use-sync-external-store#usesyncexternalstore Avoiding Hydration Mismatches with useSyncExternalStore}
   */
  const isServer = useSyncExternalStore(
    emptySubscribe,
    () => false,
    () => true
  );

  return isServer ? fallback : children;
};

/**
 * A higher order component that will only render the wrapped component on the client.
 * @param Component - The component to wrap.
 * @param fallback - The fallback component to render on the server.
 * @returns The wrapped component that will only render on the client.
 */
export function withSkipRenderOnServer<P extends object>(
  Component: FC<P>,
  fallback?: ReactNode
): FC<P> {
  return (props) => (
    <SkipRenderOnServer fallback={fallback}>
      <Component {...props} />
    </SkipRenderOnServer>
  );
}
