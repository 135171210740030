import { TupleTypeToUnionType } from '@/type-utils';
import { ZodTypeAny, z } from 'zod';
import { IExperienceToDecisionsMapping } from './experiences';

/**
 * This is a list of decisions with zod schemas for each decision. This way you can define the props
 * used by the decision component.
 *
 * If you are getting a type error here, it likely means that a decision ID
 * in `IExperienceToDecisionsMapping` is missing from the keys of `decisionSchemas`. If you added a
 * new decision ID for some dynamic experience, please make sure to also include a schema for the props
 * that decision will receive for personalization.
 */
export const decisionPropsSchemas = {
  // Decisions for testExperience:
  'decision-testExperience-1': z.undefined(),
  'decision-testExperience-2': z.object({
    title: z.string()
  }),
  'decision-testExperience2-1': z.undefined(),
  'decision-testExperience2-2': z.undefined(),
  // Decisions for directRecommendations
  homepage: z.object({
    title: z.string(),
    productList: z.string()
  }),
  // Decisions for styledExperiences
  'styledExperience-header': z.object({
    style: z.string()
  }),
  'styledExperience-footer': z.object({
    style: z.string()
  }),
  // Decisions for ab-test-header
  'ab-header-decision-sticky': z.object({}),
  'ab-header-decision-static': z.object({}),
  // Decisions for ab-pdp-shoe-height-appearance
  'ab-pdp-shoe-height-image': z.object({}),
  'ab-pdp-shoe-height-button': z.object({}),
  // Decisions for ab-content-text-position
  'ab-content-text-top-left': z.object({}),
  'ab-content-text-bottom-right': z.object({})
} satisfies Record<
  TupleTypeToUnionType<
    IExperienceToDecisionsMapping[keyof IExperienceToDecisionsMapping]
  >,
  ZodTypeAny
>;
