import { HoverIntent } from '@/react/components/utility/HoverIntent';
import { classes } from '@/next-utils/css-utils/scss-utils';
import type { IColorVariationAttribute } from '@/services/models/Product/variation-attributes';
import type { FunctionComponent, MouseEventHandler } from 'react';

import type { VoidCallback } from '@/type-utils';
import S from './styles.module.scss';

/**
 * Represents the style object for the possible backgrounds for the swatch component.
 *
 * It can be a solid color or a linear gradient if the product swatch has multiple colors.
 */
interface ISwatchBackgroundStyleObject {
  /**
   * The background color for the swatch. Usually a single hex color value.
   */
  backgroundColor?: string;
  /**
   * The background image for the swatch. Usually a linear gradient with two colors.
   */
  backgroundImage?: string;
}

/**
 * Represents a swatch variation for the product tile.
 *
 * It extends the IColorVariationAttribute interface to support multiple colors.
 */
export interface ISwatchVariation
  extends Omit<IColorVariationAttribute, 'hexValue'> {
  /**
   * The SKU of the product variation. This is used to identify the product variation.
   */
  sku: string;

  /**
   * Hex value for setting the look of a color swatch. Combinations are represented in an array of strings.
   *
   * Overrides the hexValue property from the IColorVariationAttribute interface to support multiple colors.
   * */
  hexValue: string | Array<string>;
}

interface ISwatchProps extends Omit<ISwatchVariation, 'sku'> {
  /** If the swatch is currently selected. */
  active?: boolean;
  /**
   * Callback to run on swatch click.
   *
   * @param event
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /** Callback to run on hover. */
  onHover?: VoidCallback;

  /** Mouse leave action. */
  onLeave?: VoidCallback;
}

// linear-gradient(-45deg, #E3D4BF 0%, #E3D4BF 50%, #C8E0E0 50%, #C8E0E0 50%);

/**
 *  Get the background style object for the swatch from the swatch hex value.
 *
 *  @param hexValue - The hex value of the swatch.
 *  @returns The background style object, that can be a linear gradient or a single color.
 */
const getSwatchBackgroundStyleObject = (
  hexValue: string | Array<string>
): ISwatchBackgroundStyleObject => {
  if (Array.isArray(hexValue)) {
    if (hexValue.length <= 1) {
      return {
        // Default fallback color to white to ensure the swatch is built.
        backgroundColor: hexValue?.[0] ?? '#fff'
      };
    }

    // If there is more than one color, create a linear gradient splitting the colors in 45 degrees.
    return {
      backgroundImage: `linear-gradient(-45deg, ${hexValue[0]} 0%, ${hexValue[0]} 50%, ${hexValue[1]} 50%, ${hexValue[1]} 50%)`
    };
  }

  // If the color is represented as a string, return as background color.
  return {
    backgroundColor: hexValue ?? '#fff'
  };
};

/**
 * A swatch for variations in the the product tile.
 */
export const Swatch: FunctionComponent<ISwatchProps> = ({
  active,
  hexValue,
  onHover,
  onClick,
  onLeave
}) => {
  return (
    <HoverIntent onHoverIn={onHover} onHoverOut={onLeave} delay={0}>
      <button className={S.button} type="button" onClick={onClick}>
        <div
          className={classes(S.swatch, active ? S.active : '')}
          style={getSwatchBackgroundStyleObject(hexValue)}
        />
      </button>
    </HoverIntent>
  );
};
