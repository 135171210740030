'use client';

import { ECOMM_OVERRIDE_COOKIE_NAME } from '@/configs/env/public';
import { useLocale } from '@/react/hooks/useLocale';
import { useRouter } from '@/react/utils/router-utils/useRouter';
import CartVM from '@/react/view-models/CartVM';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import CookieService from '@/services/isomorphic/CookieService';
import UserService from '@/services/isomorphic/UserService';
import type { CookieModel } from '@/services/models/Cookie';
import { UserModel } from '@/services/models/User';
import type { Nullable } from '@/type-utils';
import { observer } from 'mobx-react-lite';
import {
  PropsWithChildren,
  useDeferredValue,
  useEffect,
  useMemo,
  useState
} from 'react';
import { GlobalContext, IGlobalContext } from './GlobalContext';

/**
 * The global provider which provides data that may be needed on every page
 * and might be required by multiple components on any given page.
 * These include:
 * Cart - A unified cart that can be shared by multiple components on any page.
 */
export const GlobalProvider = observer(function GlobalProvider({
  children
}: PropsWithChildren) {
  const { language, country } = useLocale();
  const [cart] = useState<CartVM>(() => new CartVM());
  const [user, setUser] = useState<Nullable<UserModel>>(null);
  const router = useRouter();

  const [
    shouldOverrideEcommerceEnablement,
    setShouldOverrideEcommerceEnablement
  ] = useState<boolean>(false);

  useEffect(() => {
    // Check for the ecommerce override cookie to force-enable ecommerce functionality. We
    // merely check for the presence of the cookie on the client-side as we cannot expose
    // the secret value of the cookie to the client-side. Fortunately, the display of an
    // ecommerce-related component has no practical effect if the server-side does not
    // also allow for ecommerce functionality.
    const cookie: Nullable<CookieModel> = CookieService.tryGet(
      ECOMM_OVERRIDE_COOKIE_NAME
    );
    setShouldOverrideEcommerceEnablement(
      Boolean(cookie && typeof cookie.value === 'string')
    );
  }, []);

  const isEcommerceEnabled = ConfigurationService.getConfig(
    'ecommerce',
    language,
    country
  ).getSetting('ecommerceEnabled').value;

  const isReloadedOnEveryPage = ConfigurationService.getConfig(
    'cart',
    language,
    country
  ).getSetting('isReloadedOnEveryPage').value;

  const shouldRefreshCart =
    isEcommerceEnabled || shouldOverrideEcommerceEnablement;

  // initializes the cart globally for the application
  useEffect(
    function initializeCart() {
      if (shouldRefreshCart) {
        cart.refresh();
      }
    },
    [cart, shouldRefreshCart]
  );

  // if route is changed and the cart should be reloaded
  // on every page, then refresh it
  useEffect(
    function refreshCartOnNavigation() {
      // Only refresh the cart if it is "ready". This is to prevent
      // duplicate initialization of the cart, since the `initializeCart`
      // effect will handle the initial refresh.
      if (cart.isReady && isReloadedOnEveryPage && shouldRefreshCart) {
        cart.refresh();
      }
    },
    [router.pathname, cart, isReloadedOnEveryPage, shouldRefreshCart]
  );

  // Handle updating the UserModel.
  useEffect(
    function setAndRefreshUser() {
      let isStale = false;

      const getUser = async (): Promise<void> => {
        const currentUser = await UserService.getCurrentUser(true);
        const userModel = UserModel.from(currentUser);

        if (isStale) return;
        setUser(userModel);
      };
      getUser();

      return () => {
        isStale = true;
      };
    },
    [router.pathname]
  );

  const contextValue = useMemo<IGlobalContext>((): IGlobalContext => {
    return {
      cart,
      user
    };
  }, [cart, user]);

  const deferredContextValue = useDeferredValue(contextValue);

  return (
    <GlobalContext.Provider value={deferredContextValue}>
      {children}
    </GlobalContext.Provider>
  );
});
