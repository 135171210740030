'use client';

// Import libraries
import { observer } from 'mobx-react-lite';
import Image, { StaticImageData } from 'next/image';
import { FunctionComponent, useState } from 'react';

// Import services
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import { msgf } from '@/services/isomorphic/I18NService';

// Import unit structs
import { IProduct } from '@/services/models/Product';

import { IImage } from '@/services/models/Media/Image';
import { classes } from '@/next-utils/css-utils/scss-utils';

// Import components
import { PriceModel } from '@/services/models/Price';
import { EventType } from '@/services/isomorphic/UserInteractionService';
import { Nullable } from '@/type-utils';
import { isNullish } from '@/utils/null-utils';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { useProductTile } from '@/react/hooks/useProductTile';
import { Link } from '../../core-ui/Link';
import RatingStars from '../../core-ui/RatingStars';
import QuickviewButton from '../quickview/QuickviewButton';
import { Price } from '../../product/Price';
import ImageSwitcher from '../../utility/ImageSwitcher';
import SkeletonLoader from '../../utility/SkeletonLoader';
import { Image as GenericImage } from '../../core-ui/Image';
import ITestIdentifiable from '../../traits/ITestIdentifiable';
import ColorSelector, { ColorOption } from './ColorSelector';
import { ProductSwatchSlider } from './ProductSwatchSlider';
import { HoverIntent } from '../../utility/HoverIntent';
import type { ISwatchVariation } from './ProductSwatchSlider/Swatch';

// Import styles and assets
import newStyleBanner from './static/badges/new_style.png';

import S from './styles.base.module.scss';
import { product_tiles_numberOfColors } from "@/lang/__generated__/ahnu/product_tiles_numberOfColors";

/** Defines the possible badge types a product tile can have. */
export enum ProductBadge {
  newStyle = 'newStyle'
}

/**
 * Utility to match a `ProductBadge` enum to its corresponding image.
 * @param badge - The badge to use.
 * @returns The badge image or `null` if the supplied badge type is invalid.
 */
const getBadgeImage = (badge: ProductBadge): StaticImageData | null => {
  switch (badge) {
    case ProductBadge.newStyle:
      return newStyleBanner;

    default:
      return null;
  }
};

/** Defines props for the props `ProductTile` component. */
export interface IProductTileProps extends ITestIdentifiable {
  /** The product to display. */
  product: Nullable<IProduct>;

  /** A badge to include on this tile. */
  badge?: ProductBadge;

  /** Promotional text to include on this tile. */
  promotionText?: string;

  /** Defines tile type. */
  variant?: 'default' | 'recommendation' | 'cart';

  /** Is recommendation tile in a slide. */
  isSlideTile?: boolean;
}

/** Component that displays a product in a results or category page.  */
const ProductTile: FunctionComponent<IProductTileProps> = observer(
  ({
    product,
    badge,
    promotionText,
    variant = 'default',
    isSlideTile,
    datatestID
  }) => {
    const pdpConfig = ConfigurationService.getConfig('product');
    const shouldUseQuickColorSelector = pdpConfig.getSetting(
      'plp.useQuickColorSelector'
    ).value;

    const productTileViewModel = useProductTile(product);

    const { productModel, colorVariants } = productTileViewModel ?? {};

    const { name, primaryImage, secondaryImage, price } = productModel ?? {};

    const [currentImage, setCurrentImage] =
      useState<Nullable<IImage>>(primaryImage);

    // If the product model is not yet available...
    if (!productModel) {
      // Render a tile container with skeleton loaders.
      return (
        <div
          className={classes(S.productTile, S.loading, {
            [S.recommendation]: variant === 'recommendation',
            [S.slideTile]: isSlideTile || false
          } as { [key: string]: boolean })}
          data-testid={datatestID}
        >
          <div className={classes(S.content, S.loading)}>
            <div className={S.imageContainer}>
              <SkeletonLoader className={S.skeletonLoader} />
            </div>

            <div className={S.productName}>
              <SkeletonLoader className={S.skeletonLoader} />
            </div>

            <div className={S.infoRow}>
              <SkeletonLoader className={S.skeletonLoader} />
            </div>
          </div>
        </div>
      );
    }

    const { origin, pathname } = EnvironmentService.url;

    const priceModel = price ? PriceModel.from(price) : null;

    // Create swatches for each color variant.
    const swatches =
      colorVariants?.map((color) => {
        const [sku] = productModel.selectAttributeSKU(color);

        return {
          sku,
          ...color
        };
      }) ?? [];

    // Retrieve paths to send to product page from a category page.
    const href = productModel.getUrlWithCategory(pathname);

    const variants = {
      default: variant === 'default',
      recommendation: variant === 'recommendation',
      cart: variant === 'cart'
    };

    const shouldDisplayColorSelector =
      !isNullish(colorVariants) && colorVariants.length > 1 && !variants.cart;

    const onSwatchChange = async ([swatch, previousSwatch]: [
      ISwatchVariation,
      Nullable<ISwatchVariation>
    ]): Promise<void> => {
      if (swatch.sku === previousSwatch?.sku) {
        return;
      }

      if (swatch.secondaryImage) {
        setCurrentImage(swatch.secondaryImage);
      }

      await productTileViewModel?.selectProductVariantBySKU(swatch.sku);
    };

    return (
      <HoverIntent
        delay={0}
        onHoverIn={() => {
          if (!variants.cart) setCurrentImage(secondaryImage);
        }}
        onHoverOut={() => {
          if (!variants.cart) setCurrentImage(primaryImage);
        }}
      >
        <div
          className={classes(
            !variants.cart ? S.productTile : '',
            variants.recommendation ? S.recommendation : '',
            isSlideTile ? S.slideTile : '',
            variants.cart ? S.cart : '',
            !shouldUseQuickColorSelector ? S.withProductSwatchSlider : ''
          )}
          data-testid={datatestID}
        >
          <div
            className={classes(
              S.content,
              !shouldUseQuickColorSelector ? S.withProductSwatchSlider : ''
            )}
          >
            {badge != null ? (
              <div className={S.badge}>
                <Image
                  // Temporary badge id as alt tag.
                  alt={badge}
                  className={S.image}
                  src={getBadgeImage(badge) as StaticImageData}
                  fill
                />
              </div>
            ) : null}

            {!variants.cart && (
              <div className={S.quickShopButton}>
                <QuickviewButton product={productModel} />
              </div>
            )}
            {!variants.cart ? (
              <Link
                href={href}
                variant="text"
                interactionDetails={{
                  action: EventType.TileClick,
                  product: productModel
                }}
              >
                <div className={S.imageContainer}>
                  {currentImage ? (
                    <ImageSwitcher
                      image={currentImage}
                      imageProps={{
                        fit: 'contain'
                      }}
                      imageClassName={
                        variants.recommendation ? S.slideTileImage : undefined
                      }
                    />
                  ) : null}
                </div>
              </Link>
            ) : (
              <Link
                href={href}
                variant="text"
                interactionDetails={{
                  action: EventType.TileClick,
                  product: productModel
                }}
              >
                <div className={S.imageContainer}>
                  {primaryImage ? (
                    <GenericImage image={primaryImage} />
                  ) : (
                    <SkeletonLoader />
                  )}
                </div>
              </Link>
            )}

            {shouldDisplayColorSelector && !shouldUseQuickColorSelector && (
              <div className={S.productSwatchRow}>
                <ProductSwatchSlider
                  swatches={swatches}
                  swatchesPerSlide={4}
                  onChange={onSwatchChange}
                />
              </div>
            )}

            <Link
              href={href.toString()}
              variant="text"
              interactionDetails={{
                action: EventType.TileClick,
                product: productModel
              }}
              datatestID="plpProductName"
            >
              <div className={S.productName}>{name}</div>
            </Link>
            {!variants.cart && (
              <div className={S.ratings}>
                {!productModel.ratings.pending ? (
                  <RatingStars
                    rating={productModel.ratings.value?.averageRating ?? 0}
                  />
                ) : (
                  // A skeleton loading indicator would go well here.
                  <SkeletonLoader className={S.skeletonLoader} />
                )}
              </div>
            )}

            <div className={S.infoRow}>
              {colorVariants ? (
                <>
                  {priceModel && (
                    <Price price={priceModel} className={S.currentPrice} />
                  )}

                  {/* Colors/variants */}
                  {colorVariants.length > 0 && (
                    <>
                      <div className={S.variantsDivider}>/</div>
                      <div className={S.variants}>
                        {msgf(product_tiles_numberOfColors, {
                          itemCount: colorVariants.length
                        })}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <SkeletonLoader className={S.skeletonLoader} />
              )}
            </div>

            {shouldDisplayColorSelector && shouldUseQuickColorSelector ? (
              <div className={S.colors}>
                <ColorSelector>
                  {/* TODO: Use actual variant colors here. */}
                  {colorVariants.map((color, i) => {
                    const [newSKU, attributesChanged] =
                      productModel.selectAttributeSKU(color);
                    const url = new URL(`/p/${newSKU}`, origin);

                    return (
                      <ColorOption
                        key={newSKU}
                        color={color}
                        onHover={() => {
                          if (color.secondaryImage) {
                            setCurrentImage(color.secondaryImage);
                          }
                        }}
                        href={url}
                      />
                    );
                  })}
                </ColorSelector>
              </div>
            ) : null}

            {!isNullish(promotionText) && (
              <div className={S.promotionText}>{promotionText}</div>
            )}
          </div>
        </div>
      </HoverIntent>
    );
  }
);

export default ProductTile;
