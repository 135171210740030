import type { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { InvalidStateError } from '@/utils/errors';

export const { NoRequestAvailableError } = errorFactory(
  'NoRequestAvailableError',
  InvalidStateError
);

/**
 * An error thrown for when a request is required but not available at all.
 */
export type NoRequestAvailableError = InstanceTypeOf<
  typeof NoRequestAvailableError
>;
