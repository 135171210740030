import { InstanceTypeOf } from '@/type-utils';
import type { IHTTPErrorMetaData } from '../api-utils/IHTTPErrorMetaData';
import { errorFactory } from '../error-utils';

export const { RequestConflictError } = errorFactory('RequestConflictError', {
  mapsToHTTPError: 'ConflictError'
} as IHTTPErrorMetaData);

/** Generic Error to use when a request is not successful due to a 409 conflict. */
export type RequestConflictError = InstanceTypeOf<typeof RequestConflictError>;
