import type { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';

export const { UnableToParsePhoneNumberError } = errorFactory(
  'UnableToParsePhoneNumberError'
);

/** Generic error to use when a phone number parse operation fails. */
export type UnableToParsePhoneNumberError = InstanceTypeOf<
  typeof UnableToParsePhoneNumberError
>;

export const { InvalidPhoneNumberCountryError } = errorFactory(
  'InvalidPhoneNumberCountryError',
  UnableToParsePhoneNumberError
);

/**
 * Error to use when an a phone number has an invalid country code.
 */
export type InvalidPhoneNumberCountryError = InstanceTypeOf<
  typeof InvalidPhoneNumberCountryError
>;

export const { InvalidPhoneNumberValueError } = errorFactory(
  'InvalidPhoneNumberValueError',
  UnableToParsePhoneNumberError
);

/**
 * Error to use when an unparsable value is passed to the phone number parse function.
 * (e.g. A string with letters or symbols other than parentheses, dashes, pluses, and/or periods).
 */
export type InvalidPhoneNumberValueError = InstanceTypeOf<
  typeof InvalidPhoneNumberValueError
>;

export const { InvalidPhoneNumberLengthError } = errorFactory(
  'InvalidPhoneNumberLengthError',
  UnableToParsePhoneNumberError
);

/**
 * Error to use when a phone number is of an invalid length.
 */
export type InvalidPhoneNumberLengthError = InstanceTypeOf<
  typeof InvalidPhoneNumberLengthError
>;

export const { PhoneNumberTooShortError } = errorFactory(
  'PhoneNumberTooShortError',
  InvalidPhoneNumberLengthError
);

/** Error to use when a phone number is too short. */
export type PhoneNumberTooShortError = InstanceTypeOf<
  typeof PhoneNumberTooShortError
>;

export const { PhoneNumberTooLongError } = errorFactory(
  'PhoneNumberTooLongError',
  InvalidPhoneNumberLengthError
);

/** Error to use when a phone number is too long. */
export type PhoneNumberTooLongError = InstanceTypeOf<
  typeof PhoneNumberTooLongError
>;
