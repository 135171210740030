'use client';

import { useDebounce } from '@/react/hooks/useDebounce';
import { arrayEquals } from '@/utils/array-utils';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import type { IRawBreakpoint } from './breakpoints';
import { getRawBreakpoints, hasAnyBreakpointMatch } from './helpers';

/**
 * Transform the children of <Breakpoints> element into an array of objects
 * that describe which elements to render for which breakpoints.
 * Basically a memoized version of {@link getRawBreakpoints}.
 *
 * @param children - The children of the <Breakpoints> element.
 * @returns An array of raw breakpoints.
 * @see {@link getRawBreakpoints}
 */
export const useRawBreakpoints = (
  children: ReactNode
): ReturnType<typeof getRawBreakpoints> => {
  return useMemo(() => {
    return getRawBreakpoints(children);
  }, [children]);
};

/**
 * Iterates over an array of breakpoints and based on the current screen size
 * determines which of those breakpoints are currently not active (invisible).
 * This helper is used in unmounting the invisible trees.
 *
 * @param rawBreakpoints - An array of raw breakpoints.
 * @returns An array of indices for invisible (inactive) breakpoints.
 */
const getInvisibles = (
  rawBreakpoints: Array<IRawBreakpoint>
): Array<number> => {
  const invisibles: Array<number> = [];
  rawBreakpoints.forEach(({ breakpoints }, index) => {
    if (!hasAnyBreakpointMatch(breakpoints)) {
      invisibles.push(index);
    }
  });

  return invisibles;
};

/**
 * Determines which breakpoints are invisible and returns their indices
 * (Indices in terms of their index in the `<Breakpoints>`).
 *
 * Use case: The SSR render renders out all the elements (the HTML) and sends
 * it to the client, where CSS "immediately" decides which HTML tree to show.
 * After that, the React render is run but only for hydration purposes.
 * However, during that hydration phase, if the tree produced by the client-side
 * render differs, hydration has to listen. That is when the client-side will
 * unmount all of the trees that are not visible, forcing hydration to delete them
 * from the DOM to prevent some trees from running side-effects and hooks when
 * in fact they are hidden (and also to free up the DOM).
 *
 * This hook also listens to the window resize event, so when the browser
 * resizes, it will cause the indices to change
 * and on the next render for the correct tree to be mounted.
 *
 * Although this is not ideal, handling resize events gracefully is not a priority.
 * This actually yields some memory optimizations because most screens will stay the same
 * size throughout the session of the user.
 *
 * @param rawBreakpoints - The raw breakpoints.
 * @returns Indices of children that are invisible.
 */
export const useIndicesOfInvisibleChildren = (
  rawBreakpoints: Array<IRawBreakpoint>
): Array<number> => {
  /**
   * If we initialize the state with current invisibles, this will
   * ensure that the trees are unmounted on the first render.
   * If we change this to an empty array, the first client-side render
   * will end up producing the same tree as the SSR, and then the useEffect
   * will be responsible for unmounting the trees.
   */
  const [invisibles, setInvisibles] = useState(() =>
    getInvisibles(rawBreakpoints)
  );

  const debounce = useDebounce();

  // We assume that rawBreakpoints never change! As they shouldn't.
  useEffect(() => {
    const onResize = (): void => {
      const newInvisibles = getInvisibles(rawBreakpoints);

      setInvisibles((invisibles) =>
        // If the arrays are the same, don't update the state.
        arrayEquals(invisibles, newInvisibles) ? invisibles : newInvisibles
      );
    };

    const debounceOnResize = (): void => {
      debounce(50, onResize);
    };

    window.addEventListener('resize', debounceOnResize);
    return () => window.removeEventListener('resize', debounceOnResize);
  }, []);

  return invisibles;
};
