'use client';

import { classes } from '@/next-utils/css-utils/scss-utils';
import type { FC, MouseEventHandler } from 'react';
import { Image } from '../../../core-ui/Image';
import {
  useSliderController,
  useSliderOptions,
  useSliderState
} from '../../utils/hooks';
import { getThumbnail } from './helpers';

import S from './styles.base.module.scss';

/**
 * Pagination wrapper. Renders the pagination bullets or thumbnails.
 * @throws - If thumbnails are provided but don't match the slides in count.
 */
export const SliderPagination: FC = () => {
  const sliderState = useSliderState();
  const sliderController = useSliderController();
  const options = useSliderOptions().pagination;

  const {
    // Grab the direction from the Slider pagination options,
    // which reflects the direction specified for the thumbnails.
    direction,
    inset,
    position,

    buttonClassName,
    thumbnailClassName,
    activeClassName,
    inactiveClassName,
    wrapperClassName,

    thumbnails = []
  } = options;

  if (thumbnails.length > 0 && thumbnails.length !== sliderState.total) {
    // Originally, this component would throw an error in this case.
    // However, it's not clear what the intention of it was, since we don't explicitly handle
    // a render error in the parent component, but we don't want to break the page. So unless
    // the intention is clarified, it makes more sense to return null here.
    return null;
  }

  const children = [];

  for (let i = 0; i < sliderState.total; i++) {
    const onClick: MouseEventHandler<HTMLButtonElement> = () => {
      sliderController.goto(i);
    };

    const thumbnail = getThumbnail(thumbnails[i], i);
    const title = `Goto slide ${i + 1}`;

    children.push(
      <button
        key={i}
        type="button"
        onClick={onClick}
        className={classes(
          S.button,
          buttonClassName,
          thumbnail && S.hasThumbnail,
          ...(i === sliderState.current
            ? [S.active, activeClassName]
            : [S.inactive, inactiveClassName])
        )}
        title={title}
        aria-label={title}
      >
        {thumbnail && (
          <Image
            image={{
              uuid: '',
              src: thumbnail.src,
              alt: thumbnail.alt
            }}
            className={classes(
              S.thumbnail,
              thumbnailClassName,
              thumbnail.className
            )}
            aria-hidden
          />
        )}
      </button>
    );
  }

  const finalPosition =
    position ?? (direction === 'vertical' ? 'left' : 'bottom');

  return (
    <div
      className={classes(
        S.wrapper,
        direction === 'vertical' ? S.vertical : S.horizontal,
        wrapperClassName,
        inset && S.inset
      )}
      data-position={finalPosition}
    >
      {thumbnails.length || children.length > 1 ? children : ''}
    </div>
  );
};
