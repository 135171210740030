import { Builder } from '@builder.io/react';
import dynamic from 'next/dynamic';

import {
  block,
  uiImages,
  media
} from '@/react/components/content/registry/data';
import { memo } from 'react';
import {
  defaultMediaValues,
  mediaSubfields
} from '../../registry/data/components/media';

/**
 * Register the StyleBlock component.
 */
const register = (): void => {
  Builder.registerComponent(memo(dynamic(async () => import('./index'))), {
    name: 'ContentColorsProductTile',
    inputs: [
      {
        name: 'productColors',
        type: 'list',
        subFields: [
          block,
          {
            name: 'textColor',
            type: 'string',
            enum: ['light', 'dark'],
            defaultValue: 'light'
          },
          {
            name: 'hoverTextColor',
            type: 'string',
            enum: ['light', 'dark'],
            defaultValue: 'dark'
          },
          media,
          {
            name: 'hoverMedia',
            type: 'object',
            subFields: mediaSubfields,
            defaultValue: {
              defaultMedia: defaultMediaValues,
              desktopMedia: defaultMediaValues
            }
          },
          {
            name: 'color',
            type: 'color',
            defaultValue: '#E9F2B4'
          }
        ]
      }
    ],
    image: uiImages.cubeImage
  });
};

export default register;
