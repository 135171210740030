import { Icon, IconTypes } from '@/react/components/core-ui/Icon';
import type { ISliderNavigationButtonIconProps } from '@/react/components/Slider/components/Navigation/SliderNavigationButtonIcon/ISliderNavigationButtonIconProps';
import type { FunctionComponent } from 'react';
import { classes } from '@/next-utils/css-utils/scss-utils';

import S from './styles.module.scss';

/**
 * Navigation button for the swatch slider. Used in the ProductSwatchSlider.
 *
 * @param direction - The direction of the button, automatically injected by the Slider.
 */
export const SwatchNavigationButton: FunctionComponent<
  ISliderNavigationButtonIconProps
> = ({ direction }) => {
  return (
    <Icon
      className={classes(S.navigationIcon, S[direction])}
      icon={
        direction === 'left'
          ? IconTypes.CircleChevronLeftLight
          : IconTypes.CircleChevronRightLight
      }
    />
  );
};
