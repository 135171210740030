'use client';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { msg } from '@/services/isomorphic/I18NService';
import { useWindowHeight } from '@react-hook/window-size';
import { memo, useEffect, useState } from 'react';
import { Button } from '../../core-ui/Button';
import { Icon, IconSizeProp, IconTypes } from '../../core-ui/Icon';

import S from './styles.module.scss';
import { general_backToTop } from "@/lang/__generated__/ahnu/general_backToTop";
import { general_backToTopButton } from "@/lang/__generated__/ahnu/general_backToTopButton";

/**
 * A button that scrolls the user to the top of the page.
 *
 * 1. Only appears on pages that are larger than 3x the viewport/element height.
 * 2. Appears after both conditions:
 *  a. The user has scrolled beyond 2x the viewport/element height.
 *  B. The user has begun scrolling up.
 * 3. Disappears after either conditions:
 *  a. The user has scrolled before 2x the viewport/element height.
 *  B. The user has begun scrolling down.
 */
export const BackToTopButton = memo(function BackToTopButton() {
  const [isHidden, setIsHidden] = useState(true);
  const windowHeight = useWindowHeight();

  // The target height is the height at which the button should appear.
  const targetHeight = useBrandLocaleValue<number>(
    () => ({
      default: windowHeight * 3,
      AHNU: windowHeight
    }),
    [windowHeight]
  );

  const buttonIcon = useBrandLocaleValue<IconTypes>(
    () => ({
      default: IconTypes.ArrowUp,
      AHNU: IconTypes.ChevronUp
    }),
    []
  );

  const scrollBehavior = useBrandLocaleValue<ScrollBehavior>(
    () => ({
      default: 'smooth',
      AHNU: 'auto'
    }),
    []
  );

  const buttonPosition = useBrandLocaleValue<'right' | 'left'>(
    () => ({
      default: 'right',
      AHNU: 'left'
    }),
    []
  );

  useEffect(
    function attachScrollListener() {
      let lastScrollY = window.scrollY;

      const handleScroll = (): void => {
        const currentScrollY = window.scrollY;
        const scrollDelta = currentScrollY - lastScrollY;

        // hide the button if scrolling down, or if the user is below the target height
        setIsHidden(scrollDelta > 0 || currentScrollY < targetHeight);
        lastScrollY = currentScrollY;
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    },
    [targetHeight]
  );

  return (
    <div className={S.container}>
      <Button
        variant="secondary"
        className={classes(
          S.button,
          isHidden && S.hidden,
          buttonPosition === 'right' ? S.right : S.left
        )}
        title={msg(general_backToTop)}
        ariaLabel={msg(general_backToTop)}
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: scrollBehavior
          });
        }}
      >
        <div className={S.content}>
          <span className={S.label}>{msg(general_backToTopButton)}</span>
          <Icon
            className={S.icon}
            size={IconSizeProp.SizeLG}
            icon={buttonIcon}
          />
        </div>
      </Button>
    </div>
  );
});
