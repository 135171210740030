import { DEFAULT_REGION } from '@/configs/env/public';
import { Country } from '@/constructs/Country';
import { Language } from '@/constructs/Language';
import type { ILocale } from '@/constructs/LocaleSchema';
import type { LocalizationStringMap, LocalizationStringPath } from '@/lang';
import type { Primitive } from '@/type-utils';
import StaleWhileRevalidate from '@/utils/StaleWhileRevalidate';
import type { InvalidArgumentError } from '@/utils/errors';
import { exhaustiveGuard } from '@/utils/function-utils';
import type { ReactElement } from 'react';
import I18NService from './I18NService';
import { countries_US } from "@/lang/__generated__/ahnu/countries_US";
import { countries_UK } from "@/lang/__generated__/ahnu/countries_UK";
import { countries_CA } from "@/lang/__generated__/ahnu/countries_CA";
import { countries_GB } from "@/lang/__generated__/ahnu/countries_GB";
import { countries_DE } from "@/lang/__generated__/ahnu/countries_DE";
import { countries_FR } from "@/lang/__generated__/ahnu/countries_FR";
import { countries_IT } from "@/lang/__generated__/ahnu/countries_IT";
import { countries_JP } from "@/lang/__generated__/ahnu/countries_JP";
import { countries_NL } from "@/lang/__generated__/ahnu/countries_NL";
import { language_en } from "@/lang/__generated__/ahnu/language_en";
import { language_es } from "@/lang/__generated__/ahnu/language_es";
import { language_fr } from "@/lang/__generated__/ahnu/language_fr";
import { language_de } from "@/lang/__generated__/ahnu/language_de";
import { language_it } from "@/lang/__generated__/ahnu/language_it";
import { language_jp } from "@/lang/__generated__/ahnu/language_jp";
import { language_nl } from "@/lang/__generated__/ahnu/language_nl";

const regionConfig = {
  US: {
    supportedCurrencies: ['USD'],
    defaultCurrency: 'USD',
    locales: ['en', 'en-US'],
    defaultLocale: 'en'
  }
} as const;

export const i18nConfig = {
  defaultRegion: DEFAULT_REGION,
  regionConfig
} as const;

export { Country } from '@/constructs/Country';
export { Language } from '@/constructs/Language';

export * from '@/constructs/LocaleSchema';
export * from '@/lang/types/Resource';

export default I18NService;

/**
 * Retrieves a localized resource string for the given identifier and locale (or fallbacks
 * to the "current" locale if none is provided). The resource string must be defined in the
 * `lang` directory.
 *
 * If the resource string contains placeholders, use {@link msgf} instead.
 *
 * @param msgID - The identifier for the resource string. It must be in the form of
 * `[file].[key].[...etc]`, where `file` is the name of the file in the `lang`,
 * `key` is the key in the exported object, and `...etc` is any sequence of nested keys
 * which resolve to a resource string.
 * @param [locale] - The lang-locale of the given message.
 * @returns The localized resource string.
 *
 * @example
 * msg('general.hello') // => 'Hello, World!'
 * msg('general.hello', JP_LOCALE) // => 'こんにちは、世界！'
 */
export function msg<Path extends LocalizationStringPath>(
  msgID: Path,
  locale?: ILocale
): LocalizationStringMap[Path] {
  return I18NService.msg(msgID, locale);
}

/**
 * Retrieves a configurable, localized resource string for the given identifier and locale
 * (or fallbacks to the "current" locale if none is provided). The resource string must be
 * defined in the `lang` directory.
 *
 * The resource string should contain placeholders in ICU MessageFormat syntax, which can be
 * interpolated with the values provided in the `values` parameter. If the resource string
 * does not contain placeholders, use {@link msg} instead.
 *
 * @param msgID - The identifier for the resource string. It must be in the form of
 * `[file].[key].[...etc]`, where `file` is the name of the file in the `lang`,
 * `key` is the key in the exported object, and `...etc` is any sequence of nested keys
 * which resolve to a resource string.
 * @param values - A record of values to be interpolated into the string. The values
 * provided should match the placeholders in the resource string.
 * @param [locale] - The lang-locale of the given message.
 * @returns The localized resource string formatted with the passed in values in
 * accordance with the ICU MessageFormat specification.
 *
 * **Note:** The return value is not guaranteed to a string. It could be a React element
 * if the resource string is formatted with JSX. The type displayed in the IDE is just
 * there for conveniently inspecting the placeholder values.
 *
 * @see {@link https://formatjs.github.io/docs/core-concepts/icu-syntax/ ICU Message Syntax}
 * @see {@link https://formatjs.github.io/docs/intl-messageformat/#common-usage-example Common Usage Example}
 *
 * @example
 * // 'cart.quantity' => 'You have {quantity, plural, one {1 item} other {# items}} in your cart.'
 * msgf('cart.quantity', { quantity: 1 }) // => 'You have 1 item in your cart.'
 * msgf('cart.quantity', { quantity: 5 }) // => 'You have 5 items in your cart.'
 */
export function msgf<Path extends LocalizationStringPath>(
  msgID: Path,
  values: Record<string, Primitive | ReactElement | JSX.Element>,
  locale?: ILocale
): LocalizationStringMap[Path] {
  return I18NService.msgf(msgID, values, locale);
}

/**
 * @deprecated Use `msg` instead for better performance and type safety.
 *
 * Dynamically retrieves a localized resource string for the given identifier in the
 * "current" locale. The resource string must be defined in the `lang` directory.
 * The difference between this method and {@link msg} is that this method will return
 * a {@link StaleWhileRevalidate} object that can be used to asynchronously load and display
 * the resource string.
 *
 * **Note:** This method is _not_ type safe. It is recommended to use `msg`
 * instead for type safety. Furthermore, this method should only be used when the
 * resource ID is not known at compile time. The method necessarily requires that all
 * resource strings be loaded at runtime, which can have a sizeable impact on the
 * perceived performance of the application.
 *
 * **Note 2:** There is no dynamic version of `msgf` because if the resource ID is not
 * known at compile time, then it's unlikely that the values to be interpolated are known
 * either.
 *
 * @param msgID - The identifier for the resource string. It must be in the form of
 * `[file].[key].[...etc]`, where `file` is the name of the file in the `lang`,
 * `key` is the key in the exported object, and `...etc` is any sequence of nested keys
 * which resolve to a resource string.
 * @returns The localized resource string.
 */
export const msgDynamic = (msgID: string): StaleWhileRevalidate<string> => {
  return I18NService.msgDynamic(msgID);
};

/**
 * Takes a date and formats it based on the current locale and options provided.
 * @param date - The date to format.
 * @param options - Options to pass to the formatter.
 * @returns The date formatted.
 */
export const formatDate = (
  date: Date,
  options: Intl.DateTimeFormatOptions
): string => {
  return I18NService.formatDate(date, options);
};

/**
 * Retrieves the currency symbol based in current locale.
 * @returns - The current currency symbol.
 */
export const currentCurrencySymbol = (): string => {
  return I18NService.currentCurrencySymbol();
};

/**
 * Retrieves the display name for the specified country.
 *
 * **NOTE:** Names must be configured via the `countries` lang file.
 *
 * @param country - The country to get the name for.
 * @returns The specified country's display name.
 *
 * @throws An {@link InvalidArgumentError} if the specified country does not
 * have a configured display name.
 */
export const getCountryName = (country: Country): string => {
  switch (country) {
    case Country.US:
      return msg(countries_US);
    case Country.UK:
      return msg(countries_UK);
    case Country.CA:
      return msg(countries_CA);
    case Country.GB:
      return msg(countries_GB);
    case Country.DE:
      return msg(countries_DE);
    case Country.FR:
      return msg(countries_FR);
    case Country.IT:
      return msg(countries_IT);
    case Country.JP:
      return msg(countries_JP);
    case Country.NL:
      return msg(countries_NL);
  }

  return exhaustiveGuard(
    country,
    `Cannot get the display name for country "${country}": ` +
      'The country does not have a configured display name. ' +
      'Did you update the `countries` lang file ' +
      'and the mapping in `getCountryName`?'
  );
};

/**
 * Retrieves the display name for the specified language.
 *
 * **NOTE:** Names must be configured via the `language` lang file.
 *
 * @param language - The language to get the name for.
 * @returns The specified language's display name.
 *
 * @throws An {@link InvalidArgumentError} if the specified language does not
 * have a configured display name.
 */
export const getLanguageName = (language: Language): string => {
  switch (language) {
    case Language.EN:
      return msg(language_en);
    case Language.ES:
      return msg(language_es);
    case Language.FR:
      return msg(language_fr);
    case Language.DE:
      return msg(language_de);
    case Language.IT:
      return msg(language_it);
    case Language.JP:
      return msg(language_jp);
    case Language.NL:
      return msg(language_nl);
  }

  return exhaustiveGuard(
    language,
    `Cannot get the display name for language "${language}": ` +
      'The language does not have a configured display name. ' +
      'Did you update the `language` lang file ' +
      'and the mapping in `getLanguageName`?'
  );
};
