'use client';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { msg } from '@/services/isomorphic/I18NService';
import type { FC, PropsWithChildren } from 'react';
import type IStylable from '../../../traits/IStylable';
import { Icon, IconTypes } from '../../Icon';

import S from './styles.module.scss';
import { general_close } from "@/lang/__generated__/ahnu/general_close";

interface IModalHeaderProps extends PropsWithChildren, IStylable {
  /** Called when the close button is clicked. */
  onClose: () => void;

  /**
   * Class to be applied to the inner container of the modal header.
   */
  containerClass?: string;
}

/**
 * Modal Header responsible to hold title and close button.
 */
export const ModalHeader: FC<IModalHeaderProps> = ({
  className,
  containerClass,
  style,
  onClose,
  children
}) => {
  return (
    <div className={className} style={style}>
      <div className={classes(S.container, containerClass)}>
        {children}
        <Icon
          className={S.modalClose}
          title={msg(general_close)}
          icon={IconTypes.Close}
          onClick={onClose}
        />
      </div>
    </div>
  );
};
