'use client';

import { ENDLESS_AISLE_COOKIE_NAME } from '@/configs/env/public';
import CookieService from '@/services/isomorphic/CookieService';
import { msgf } from '@/services/isomorphic/I18NService';
import { useMemo, type FC } from 'react';
import { Banner } from '../../../utility/Banner';

import S from './styles.module.scss';
import { header_endlessAislePOSBanner_message } from "@/lang/__generated__/ahnu/header_endlessAislePOSBanner_message";

/** POS banner that displays above or below the header when the Magtek / Endless Isle POS is enabled. */
export const ClientOnlyPOSStoreBanner: FC = () => {
  // Check to see if the Magtek / Endless Aisle cookie is set.
  // If so, enable the Magtek / Endless Aisle POS Store banner and
  // use the cookie value as the store ID in the banner message.
  const EACookie = useMemo(
    () => CookieService.tryGet(ENDLESS_AISLE_COOKIE_NAME),
    []
  );

  const isPOSStoreEnabled = Boolean(EACookie?.value);
  const storeId = EACookie?.value ?? '';

  return (
    isPOSStoreEnabled && (
      <Banner className={S.posStoreBanner} closable={false}>
        <span>
          {msgf(header_endlessAislePOSBanner_message, {
            storeId
          })}
        </span>
      </Banner>
    )
  );
};
