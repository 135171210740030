import axios, { AxiosError, AxiosInstance } from 'axios';
import { Agent as HTTPAgent } from 'node:http';
import { Agent as HTTPSAgent } from 'node:https';

import { InvalidStateError, RequestError } from '@/utils/errors';
import CurrentRequestService from '@/services/isomorphic/CurrentRequestService';
import Service from '../../../Service';
import ConfigurationService, {
  Config
} from '../../../isomorphic/ConfigurationService';

import CookieService from '../../../isomorphic/CookieService';
import { CookieModel } from '../../../models/Cookie';
import siteCached from '../../../utils/siteCached';
import DYServiceMock from './DYServiceMock';
import { IDYChoiceConfiguration } from './schema/choice/IDYChoiceConfiguration';
import { IDYChooseVariationsResponse } from './schema/response/IDYChooseVariationsResponse';
import { IDYCookie } from './schema/response/IDYCookie';

import { EnvironmentService } from '../../../isomorphic/EnvironmentService';
import i18nService from '../../../isomorphic/I18NService';
import { DYPageType } from './DYPageType';
import { DYReportBody } from './schema/report/DYReportBody';
import { IDYReportConfiguration } from './schema/report/IDYReportConfiguration';
import { IDYUserAndSession } from './schema/report/IDYUserAndSession';
import { IDYContext } from './schema/shared/IDYContext';

/** Dynamic Yield integration service. */
export class DYService extends Service {
  /**
   * The Dynamic Yield config.
   * @returns A `Config<'dynamicYield'>`.
   */
  @siteCached
  private get config(): Config<'dynamicYield'> {
    return ConfigurationService.getConfig('dynamicYield');
  }

  /**
   * An axios instance for hitting the DY API.
   * @returns An `AxiosInstance`.
   */
  @siteCached
  private get client(): AxiosInstance {
    return axios.create({
      baseURL: this.config.getSetting('baseURL').value,
      headers: {
        'Content-Type': 'application/json',
        'dy-api-key': this.config.getSetting('dyID').value
      },
      httpAgent: new HTTPAgent({ keepAlive: true }),
      httpsAgent: new HTTPSAgent({ keepAlive: true })
    });
  }

  /**
   * Chooses no variation. This is used to set the cookies when no cookie is available.
   * @returns The user and session IDs.
   */
  private async chooseNoVariation(): Promise<{
    userID: string;
    sessionID: string;
  }> {
    const { country, language } = i18nService.currentLocale;
    const data = await this.chooseVariations({
      selector: { names: [] },
      context: {
        page: {
          type: DYPageType.Other,
          location: EnvironmentService.url.origin,
          locale: `${language}_${country}`,
          data: []
        }
      }
    });

    const {
      cookies: [userID, sessionID]
    } = data;

    return { userID: userID.value, sessionID: sessionID.value };
  }

  /**
   * Gets the user and session objects from the cookie. If the cookie does't exist, we make
   * a blank choose call so that we can populate those values. Do not use inside a choose call
   * as it is essentially redundant and will just add another call.
   * @returns The user and session objects to be used in an event call.
   * @throws If the blank choose call fails for some reason.
   */
  private async getUserAndSession(): Promise<IDYUserAndSession> {
    let dyUserIdCookie = CookieService.tryGet('_dyid_server')?.value;
    let dySessionIdCookie = CookieService.tryGet('_dyjsession')?.value;

    if (!dyUserIdCookie || !dySessionIdCookie) {
      const { userID, sessionID } = await this.chooseNoVariation();

      dyUserIdCookie = userID;
      dySessionIdCookie = sessionID;
    }

    if (!dyUserIdCookie || !dySessionIdCookie) {
      throw new InvalidStateError(
        'User id and session id cookies could not be read for this user.'
      );
    }

    const user = {
      dyid: dyUserIdCookie,
      dyid_server: dyUserIdCookie
    };

    const session = {
      dy: dySessionIdCookie
    };

    return { user, session };
  }

  /**
   * The preview parameter for the DY API. If present in the URL, the DY API will return the preview
   * version of the campaign.
   * @returns The preview parameter.
   */
  public get previewParam(): string {
    return this.config.getSetting('previewParam').value;
  }

  /**
   * Get chosen variations for one or more campaigns, either Custom Campaigns or Recommendations.
   * By default also reports a new pageview with the given context.
   *
   * @param configuration - Body object for choosing variations but without the user and session,
   * which are optional.
   * @throws `AxiosError` on HTTP errors.
   * @returns `Promise<IDYGetChoosingVariationsResponse>`.
   */
  public async chooseVariations(
    configuration: IDYChoiceConfiguration
  ): Promise<IDYChooseVariationsResponse> {
    try {
      const dyUserIdCookie = CookieService.tryGet('_dyid_server');
      const dySessionIdCookie = CookieService.tryGet('_dyjsession');

      let userAndSession = {};

      // Add the DY cookies to the request if they're available and not already present.
      if (dyUserIdCookie) {
        userAndSession = {
          user: {
            dyid: dyUserIdCookie.value,
            dyid_server: dyUserIdCookie.value
          }
        };
      }

      if (dySessionIdCookie) {
        userAndSession = {
          ...userAndSession,
          session: { dy: dySessionIdCookie.value }
        };
      }

      const body: IDYChoiceConfiguration = {
        ...configuration,
        ...userAndSession
      };

      // make the request to DY.
      const { data } = await this.client.post<IDYChooseVariationsResponse>(
        '/serve/user/choose',
        body,
        {
          headers: {
            accept: 'application/json'
          }
        }
      );

      // store the DY cookies if they were not already present.
      const {
        cookies: [newDYUserIdCookie, newDYSessionIdCookie]
      } = data;

      if (!dyUserIdCookie) {
        this.setDYCookie(newDYUserIdCookie);
      }

      if (!dySessionIdCookie) {
        this.setDYCookie(newDYSessionIdCookie);
      }

      return data;
    } catch (e) {
      if ((e as AxiosError).isAxiosError) {
        const axiosError = e as AxiosError;

        throw new RequestError(
          'Unknown error ocurred when trying calling Dynamic Yield API to get choosing variations.',
          {
            cause: axiosError
          }
        );
      }

      throw e;
    }
  }

  /**
   * The `sendReport` allows us to send `pageview`, `event` and `engagement` reports.
   * Each one of these uses a differently shaped configuration object. They are not distinct
   * calls, but the different shapes tell DY what kind of data to record.
   * @param report - Allowed `report` types.
   * @param configuration - Object based on the `report` provided. If the repoort is an
   * event it will have an events array which hold data for the individual events.
   * If it is a page view, the relevant data will be added to the context object and no
   * events data is required.
   */
  public async sendReport<R extends keyof IDYReportConfiguration>(
    report: R,
    configuration: IDYReportConfiguration[R]
  ): Promise<void> {
    try {
      const { user, session } = await this.getUserAndSession();
      const { ip } = CurrentRequestService.get()[0];

      // Adding the ip address to the context object.
      const context = {
        ...configuration.context,
        device: { ...configuration.context.device, ip: ip ?? '' }
      } as IDYContext;

      const body: DYReportBody = { ...configuration, context, user, session };

      await this.client.post(`/collect/user/${report}`, body, {
        headers: {
          accept: 'application/json'
        }
      });
    } catch (e) {
      if ((e as AxiosError).isAxiosError) {
        const axiosError = e as AxiosError;

        throw new RequestError(
          'Unknown error ocurred when trying calling Dynamic Yield API to send report.',
          {
            cause: axiosError
          }
        );
      }

      throw e;
    }
  }

  /**
   * A helper function to parse and set a DY cookie.
   * @param cookie - The DY cookie to set.
   */
  private setDYCookie(cookie: IDYCookie): void {
    CookieService.set(
      CookieModel.from({
        path: '/',
        key: cookie.name,
        value: cookie.value,
        maxAge: Number.parseInt(cookie.maxAge, 10)
      })
    );
  }
}

export default DYService.withMock(
  new DYServiceMock(DYService)
) as unknown as DYService;
