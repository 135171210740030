/**
 * A sentinel value which represents the absence of a computed value in a `LazyValue`.
 *
 * This **MUST NOT** be exported from the module since it should not be possible
 * to return from the `valueGetter` function.
 *
 * This is stored top-level rather than as a static property of `LazyValue` to
 * avoid the overhead of a prototype lookup.
 */
const None = Symbol('None');

/**
 * A `LazyValue` is a wrapper for a value that is lazily evaluated. Once the
 * value is evaluated, it is cached and returned for all future calls.
 *
 * This is useful for performance optimizations when a value is not needed
 * immediately and it may be expensive to compute. For instance, `LazyValue`
 * can be used to defer parsing a large JSON object or making an network
 * request until the data is actually needed.
 *
 * You can think of `LazyValue`s as "anonymous getters" with backing fields,
 * since the logic for retrieving the value does not belong to any particular class.
 *
 * For functional programming enthusiasts, `LazyValue` forms a monad. The `map`
 * and `flatMap` (a.k.a `bind`) methods allow you to transform the underlying
 * value without evaluating it, and the constructor acts as the `unit` function.
 *
 * @example
 * ```ts
 *  const config = new LazyValue(() => ConfigurationService.getConfig(
 *    'talonOne'
 *  ));
 *  // ...elsewhere...
 *  const talonOneConfig = config.value; // `getConfig` is called here.
 * ```
 */
export default class LazyValue<T> {
  /**
   * The cached result of evaluating the `valueGetter` function, or {@link None}
   * if the value has not been evaluated yet.
   *
   * We use a `Symbol` to represent the absence of a value because `null` and
   * `undefined` are valid values that can be returned by the `valueGetter`.
   * Moreover, comparing a value against a `Symbol` is typically more performant
   * than storing an additional boolean flag.
   */
  private cachedValue: T | typeof None = None;

  /**
   * The underlying value of the `LazyValue`.
   *
   * When accessed for the first time, `valueGetter` will be
   * evaluated and the return value will be cached for future calls.
   *
   * If the `valueGetter` function synchronously throws an error,
   * the error is **not** cached. The `valueGetter` will be called
   * for every `value` access until it returns a value.
   *
   * @returns The result of calling the `valueGetter` function.
   */
  public get value(): T {
    if (this.cachedValue === None) {
      this.cachedValue = this.valueGetter();
      // Clear the reference to the getter so that it can be garbage collected.
      // We do not use `delete` because it is slower.
      this.valueGetter = null!;
    }

    return this.cachedValue;
  }

  /**
   * Creates a new `LazyValue` instance.
   * @param valueGetter - A function used to evaluate the value of the lazy value. Use
   * the {@link LazyValue.value} property to force evaluation. If successful, the return
   * value will be cached to avoid recomputation. If the function throws a synchronous
   * error, no result is cached, and the `valueGetter` will be called for every `value`
   * access, until it returns a value.
   */
  public constructor(private valueGetter: () => T) {}

  /**
   * Creates a new `LazyValue` in which the underlying value is the result of _lazily_
   * applying the `mapper` function to the underlying value of this instance.
   *
   * **Note**: Mapping does not evaluate this instance's underlying value until the
   * underlying value of the new `LazyValue` is accessed.
   *
   * @param mapper - A function that maps the underlying value to a new value.
   * @returns A new lazy value that will evaluate the `mapper` function when
   * its `value` property is accessed.
   * @example
   * const lazyNumbers = new LazyValue(() => expensiveOperation());
   * const lazyNumStrings = lazyNumbers.map((num) => num.toString());
   */
  public map<U>(mapper: (value: T) => U): LazyValue<U> {
    return new LazyValue(() => mapper(this.value));
  }

  /**
   * Creates a new `LazyValue` in which the underlying value is the result of _lazily_
   * applying the `LazyValue`-returning `mapper` function to the underlying value of
   * this instance and then "flattening" the result.
   * @param mapper - A function that maps the underlying value to a new `LazyValue`.
   * @returns A new lazy value that will evaluate the `mapper` function when its `value`
   * property is accessed.
   * @example
   * const lazyNumbers = new LazyValue(() => expensiveOperation());
   * const lazyToString = (num: number) => new LazyValue(() => num.toString());
   * const lazyNumStrings = lazyNumbers.flatMap(lazyToString);
   */
  public flatMap<U>(mapper: (value: T) => LazyValue<U>): LazyValue<U> {
    return new LazyValue(() => mapper(this.value).value);
  }
}
