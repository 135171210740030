'use client';

import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

import { type IHeaderCategory } from '@/services/models/Content';

import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { classes } from '@/next-utils/css-utils/scss-utils';
import { EventType } from '@/services/isomorphic/UserInteractionService';
import { Nullable, Timeout } from '@/type-utils';

import { useRouter } from '@/react/utils/router-utils/useRouter';
import { Icon, IconTypes } from '../../../../../core-ui/Icon';
import { Link } from '../../../../../core-ui/Link';
import { HoverIntent } from '../../../../../utility/HoverIntent';
import { IDesktopNavbarProps } from './IDesktopNavbarProps';
import { Dropdown } from './components/Dropdown';

import S from './styles.base.module.scss';

/**
 * A constant specifying the amount of time that must pass to prove intent to hover
 * over an item.
 */
const timeoutForHoverIntent = 175;

/**
 * The Desktop navbar (Mega Nav) component. Provides dropdowns
 * and everything.
 *
 * Is NOT responsive. Must be used with Mobile component.
 */
export const Desktop: FunctionComponent<IDesktopNavbarProps> = ({
  showDropdownArrows = false,
  dropdownVisibleOn = 'hover',
  categoryClassName,
  activeCategoryClassName,
  dropdownTotalAnimationLengthMS = 750,
  dropdownSectionAnimationLengthMS = 450
}) => {
  const router = useRouter();
  const listRef = useRef<HTMLUListElement>(null);
  const timeoutRef = useRef<Nullable<Timeout>>(null);

  // Provide an option to override the CMS values for the menu.
  // The 'menu' config structure allows us to set a basic, static
  // menu structure of links.
  const menuConfig = ConfigurationService.getConfig('menu');
  const mainMenu = menuConfig.getSetting('mainMenu').value;
  const categories = mainMenu.links.value?.map(({ href, id, label }) => {
    return {
      id: id.value,
      label: label.value,
      href: href.value
    } as IHeaderCategory;
  });

  const [visible, setVisible] = useState<string | null>(null);

  // The hide callback can be used by all nodes, since it doesn't change
  const doHide = useCallback(() => {
    setVisible(null);
  }, []);

  // When we navigate to another page, close the desktop dropdown menu.
  useEffect(() => {
    const handleRouteChange = (): void => {
      doHide();
    };

    // Hide the menu on route change.
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [doHide, router]);

  // If in 'click' mode, hide the dropdown when clicked outside
  useEffect(() => {
    if (dropdownVisibleOn !== 'click') {
      return () => {};
    }

    const onDocumentClick = (e: MouseEvent): void => {
      if (!listRef.current?.contains(e.target as HTMLElement)) {
        doHide();
      }
    };

    document.body.addEventListener('click', onDocumentClick);
    return () => {
      document.body.removeEventListener('click', onDocumentClick);
    };
  }, [dropdownVisibleOn]);

  return (
    <ul className={S.nav} role="menu" ref={listRef}>
      {categories?.map((category) => {
        const { id } = category;
        const hasDropdown = category?.blocks?.length > 0;

        const doShow = (): void => {
          setVisible(id);
        };

        const doToggle = (): void => setVisible(id === visible ? null : id);

        return (
          <HoverIntent
            key={id}
            disabled={dropdownVisibleOn !== 'hover'}
            delay={timeoutForHoverIntent}
            onHoverIn={doShow}
            onHoverOut={doHide}
            timeoutRef={timeoutRef}
            // Favor the specific tab implementation here.
            tabIndex={-1}
            activatedByFocus={false}
            deactivatedByBlur={false}
          >
            <li
              tabIndex={hasDropdown ? 0 : -1}
              role="menuitem"
              className={classes(
                S.navItem,
                categoryClassName,
                ...(visible === id
                  ? [S.navItemDropdownVisible, activeCategoryClassName]
                  : [])
              )}
              aria-haspopup={hasDropdown}
              aria-expanded={hasDropdown ? visible === id : undefined}
              onKeyDown={(event): void => {
                if (event.key === ' ' || event.key === 'Enter') {
                  doToggle();

                  event.preventDefault();
                }

                if (event.key === 'Escape') {
                  doHide();
                }
              }}
            >
              <Link
                className={S.navLink}
                href={category.href}
                {...(dropdownVisibleOn === 'click'
                  ? {
                      onClick: (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        doToggle();
                      }
                    }
                  : {})}
                interactionDetails={{
                  action: EventType.NavigationLink,
                  link: {
                    linkName: category.label,
                    linkCategory: 'top-navigation',
                    linkSection: category.label
                  }
                }}
              >
                {category.label}
                {hasDropdown && showDropdownArrows && (
                  <span className={S.dropdownIcon}>
                    <Icon icon={IconTypes.AngleDown} />
                  </span>
                )}
              </Link>
              {hasDropdown && (
                <Dropdown
                  category={category}
                  visibleCategoryID={visible}
                  totalAnimationTimeMS={dropdownTotalAnimationLengthMS}
                  sectionTransitionMS={dropdownSectionAnimationLengthMS}
                />
              )}
            </li>
          </HoverIntent>
        );
      })}
    </ul>
  );
};
