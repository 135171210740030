'use client';

import {
  AppBuildType,
  EnvironmentService
} from '@/services/isomorphic/EnvironmentService';
import { usePathname, useRouter } from 'next/navigation';
import { FC, PropsWithChildren, useCallback } from 'react';
import { CommonErrorBoundary } from './CommonErrorBoundary';

/**
 * The root error boundary for the application. In production builds, it handles any rendering
 * errors not caught by descendant error boundaries by redirecting the user to the 503 page.
 */
export const RootErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
  const pathname = usePathname();
  const router = useRouter();

  const handleError = useCallback(() => {
    // Don't redirect in development mode since errors are expected as changes are made
    if ((process.env.IS_DEVELOPMENT_MODE ? "dev" : "prod") === AppBuildType.DevelopmentMode)
      return;

    if (pathname !== '/503') {
      router.push('/503');
    }
  }, [router, pathname]);

  return (
    /**
     * We specify a `key` only for the 503 page to ensure that
     * the fallback is cleared once we have navigated to it.
     *
     * Other pages do not have a key based on the `pathname`
     * to ensure that the global provider is not remounted on
     * every navigation.
     */
    <CommonErrorBoundary
      key={pathname === '/503' ? '/503' : undefined}
      onError={handleError}
      fallback={null}
    >
      {children}
    </CommonErrorBoundary>
  );
};
