import type { PropagatorType } from '@datadog/browser-rum-core/cjs/domain/tracing/tracer.types';
import {
  DATADOG_SERVICE,
  DATADOG_SESSION_SAMPLE_RATE,
  DATADOG_SESSION_REPLAY_SAMPLE_RATE,
  DATADOG_ENV,
  DATADOG_VERSION
} from '../env/public';
import config from '../config';

const datadog = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** Indicates whether or not the datadog monitoring is active. */
      enabled: true,
      /** The client token. */
      clientToken: undefined as unknown as string, // Must be overridden by the site.
      /** The application ID. */
      applicationId: undefined as unknown as string, // Must be overridden by the site.
      /** The session sample rate. */
      sessionSampleRate: Number(DATADOG_SESSION_SAMPLE_RATE ?? 5),
      /** The session replay sample rate. */
      sessionReplaySampleRate: Number(
        DATADOG_SESSION_REPLAY_SAMPLE_RATE ?? 100
      ),
      /** The environment where the log should occur.
       * Available values are: feature, dev, uat/staging, prod.
       */
      env: {
        dev: DATADOG_ENV ?? 'dev',
        qa: DATADOG_ENV ?? 'qa',
        uat: DATADOG_ENV ?? 'uat/staging',
        prod: DATADOG_ENV ?? 'prod'
      },
      /** The team that is running datadog monitoring. */
      team: 'frontend',
      /** The the service where datadog is running. */
      service: DATADOG_SERVICE,
      /** Indicates whether to track user interactions. */
      trackUserInteractions: true,
      /** Indicates whether to track resources. */
      trackResources: true,
      /** Indicates whether to track frustrations. */
      trackFrustrations: true,
      /** Indicates whether to track long tasks. */
      trackLongTasks: true,
      /** The default privacy level. */
      defaultPrivacyLevel: 'mask-user-input',
      /** Indicates whether to silently handle multiple initialization calls. */
      silentMultipleInit: true,
      /** Indicates whether to use secure session cookie. */
      useSecureSessionCookie: true,
      /** The version of the object. */
      version: DATADOG_VERSION ?? '1.0.0',
      /**
       * A URL regex to allow specific URLs that should be traced.
       * If 'enabled' attribute in otel.ts set to true, only requests to URLs that match this regex are traced.
       * If 'enabled' attribute in otel.ts set to false, no requests will be traced.
       */
      allowedTracingUrlsMatch: undefined as unknown as string, // Must be overridden by the site.
      /**
       * An array of PropagatorType to propagate trace context across different services.
       * Available values are
       * - 'datadog': Datadog's native propagator headers are used to propagate trace information specific to Datadog's tracing system.
       * - 'b3': B3 single header propagator combines multiple pieces of trace information into a single header.
       * - 'b3multi': B3 multi-header propagator splits the trace information into multiple headers.
       * - 'tracecontext': The W3C Trace Context specification, which is designed to be a cross-platform, cross-vendor standard for trace context propagation.
       * @see https://docs.datadoghq.com/real_user_monitoring/platform/connect_rum_and_traces/?tab=browserrum#how-are-rum-resources-linked-to-traces
       */
      propagatorTypes: ['tracecontext'] as Array<PropagatorType>
    }
  },
  'SANUK-US': {
    default: {
      applicationId: 'b2b1532f-a0b7-4641-8cd6-96fc7ea3addd',
      clientToken: 'pub02a80a9b7eb0518c38a1639d05cdcc26',
      service: DATADOG_SERVICE ?? 'sanuk',
      allowedTracingUrlsMatch:
        '/https://development|dev|beta|qa|www|staging.sanuk.com/'
    }
  },
  'AHNU-US': {
    default: {
      applicationId: '8a765075-fb5d-4b41-8bc7-fe91b0fd8659',
      clientToken: 'pub460860eb940a7ad13a712380165c2f7d',
      service: DATADOG_SERVICE ?? 'ahnu',
      allowedTracingUrlsMatch:
        '/https://development|dev|beta|qa|www|staging.ahnu.com/'
    }
  },
  'TEVA-US': {
    default: {
      // TODO: Update the applicationId and clientToken with the correct values for Teva. (These are the same as Ahnu.)
      applicationId: '8a765075-fb5d-4b41-8bc7-fe91b0fd8659',
      clientToken: 'pub460860eb940a7ad13a712380165c2f7d',
      service: DATADOG_SERVICE ?? 'teva',
      allowedTracingUrlsMatch:
        '/https://development|dev|beta|qa|www|staging.teva.com/'
    }
  },
  'UGG-COLLAB-US': {
    default: {
      // TODO: Update the applicationId and clientToken with the correct values for UGG Collab. (These are the same as Ahnu.)
      applicationId: '8a765075-fb5d-4b41-8bc7-fe91b0fd8659',
      clientToken: 'pub460860eb940a7ad13a712380165c2f7d',
      service: DATADOG_SERVICE ?? 'ugg-collab',
      allowedTracingUrlsMatch:
        '/https://development|dev|beta|qa|www|staging.ugg-collab.com/'
    }
  },
  'UGG-US': {
    default: {
      // TODO: Update the applicationId and clientToken with the correct values for UGG. (These are the same as Ahnu.)
      applicationId: '8a765075-fb5d-4b41-8bc7-fe91b0fd8659',
      clientToken: 'pub460860eb940a7ad13a712380165c2f7d',
      service: process.env.NEXT_PUBLIC_DATADOG_SERVICE ?? 'ugg',
      allowedTracingUrlsMatch:
        '/https://development|dev|beta|qa|www|staging.ugg.com/'
    }
  },
  'KOOLABURRA-US': {
    default: {
      // TODO: Update the applicationId and clientToken with the correct values for Teva. (These are the same as Ahnu.)
      applicationId: '8a765075-fb5d-4b41-8bc7-fe91b0fd8659',
      clientToken: 'pub460860eb940a7ad13a712380165c2f7d',
      service: process.env.NEXT_PUBLIC_DATADOG_SERVICE ?? 'teva'
    }
  },
  'HOKA-US': {
    default: {
      // TODO: Update the applicationId and clientToken with the correct values for HOKA. (These are the same as Ahnu.)
      applicationId: '8a765075-fb5d-4b41-8bc7-fe91b0fd8659',
      clientToken: 'pub460860eb940a7ad13a712380165c2f7d',
      service: process.env.NEXT_PUBLIC_DATADOG_SERVICE ?? 'hoka'
    }
  }
});

export default datadog;
