'use client';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { useRouter } from 'next/navigation';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { msg } from '@/services/isomorphic/I18NService';
import { FC, KeyboardEvent, RefObject, useState } from 'react';
import { Button } from '../../core-ui/Button';
import { Icon, IconSizeProp, IconTypes } from '../../core-ui/Icon';

import S from './styles.base.module.scss';
import { general_search } from "@/lang/__generated__/ahnu/general_search";
import { plp_search_searchPlaceholder } from "@/lang/__generated__/ahnu/plp_search_searchPlaceholder";

/**
 * Type for Search Box.
 */
export interface ISearchBoxProps {
  /**
   * Toggle show/hide function.
   * @param nextExpanded - New expanded state.
   * @deprecated
   */
  setExpanded?: (nextExpanded: boolean) => void;

  /** Input ref. */
  inputRef?: RefObject<HTMLInputElement>;

  /** String of classes for className. */
  className?: string;

  /** Style type of search box. */
  variant?: 'primary' | 'secondary';
}

/**
 * Customized Search Form with Input and Svg Icon embeded.
 */
const SearchBox: FC<ISearchBoxProps> = ({
  inputRef,
  className,
  variant = 'primary'
}) => {
  const [searchText, setSearchText] = useState('');
  const [isFocusIn, setIsFocusIn] = useState(false);

  const SearchIconType = useBrandLocaleValue<IconTypes>(
    () => ({
      default: IconTypes.Search,
      AHNU: IconTypes.ArrowRightLight
    }),
    []
  );

  const router = useRouter();
  const handleEnterSearch = (): void => {
    const searchParams = new URLSearchParams();
    if (searchText.length > 0)
      searchParams.set('keywords', JSON.stringify(searchText));

    router.push(
      `${
        ConfigurationService.getConfig('productList').getSetting('searchPath')
          .value
      }?${searchParams.toString()}`
    );
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>): void => {
    switch (e.key) {
      case 'Enter':
        handleEnterSearch();
        break;
      case 'Escape':
        inputRef?.current?.blur();
        break;
      default:
        break;
    }
  };

  return (
    <form
      role="search"
      className={S.form}
      onSubmit={(e) => e.preventDefault()}
      onFocus={() => setIsFocusIn(true)}
      onBlur={(e) => {
        // check if the element receiving focus (e.relatedTarget) is still inside the form
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setIsFocusIn(false);
        }
      }}
    >
      <Button
        title={msg(general_search)}
        datatestID="plpSearchButton"
        variant="text"
        onClick={handleEnterSearch}
        className={S.btnIcon}
        disabled={!isFocusIn}
        ariaLabel={msg(general_search)}
      >
        <Icon
          size={IconSizeProp.SizeLG}
          icon={SearchIconType}
          className={classes(
            S.icon,
            variant === 'primary' && S.primary,
            variant === 'secondary' && S.secondary
          )}
        />
      </Button>
      <input
        ref={inputRef}
        title="searchInput"
        data-testid="plpSearchInput"
        aria-label={msg(plp_search_searchPlaceholder)}
        placeholder={msg(plp_search_searchPlaceholder)}
        defaultValue={searchText}
        className={classes(
          className,
          S.searchInput,
          variant === 'primary' && S.primary,
          variant === 'secondary' && S.secondary
        )}
        onKeyDown={handleKeyPress}
        onChange={(e) => {
          if (e.target.value) {
            setSearchText(e.target.value);
          }
        }}
      />
    </form>
  );
};

export default SearchBox;
