import type { FC, PropsWithChildren } from 'react';

import S from './styles.module.scss';

/**
 * `Unprintable` component allows their children components
 * to be hidden from browser print functionality.
 */
export const Unprintable: FC<PropsWithChildren> = ({ children }) => (
  <div className={S.nonPrintable}>{children}</div>
);
