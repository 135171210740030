'use client';

import { useErrorAlert } from '@/react/hooks/useErrorAlert';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import type { FC } from 'react';
import { ErrorAlert } from '.';

/**
 * The default error alert component that will be rendered when an error is present.
 */
export const DefaultErrorAlert: FC = () => {
  const { error, closeAlert } = useErrorAlert();
    const isProd = (process.env.NEXT_PUBLIC_APP_ENV === "prod");
  return (
    Boolean(error) && (
      <ErrorAlert error={error} onClose={closeAlert} obscureMessage={isProd} />
    )
  );
};
