import { PageType, type IPage } from '@/services/models/Page';

/**
 * Generates the page from the url Path, given the same path it should always generate the same
 * page object.
 *
 * This is a straightforward way to generate the proper page object. It should work well while
 * we transition from the Pages router to the App router. A more idiomatic solution will eventually overtake.
 *
 * The page itself should probably define the pages object. Right now we need to be able to define it
 * the same each place that we need it. So this will allow us to generate the same page object in each of the
 * places we need to generate it.
 *
 * @param path - The url path.
 * @returns A simple page object.
 */
export const generatePageFromURLPath = (path: string): IPage => {
  if (path === '/') {
    return { pageType: PageType.Home, url: `${path}` };
  }

  if (path.includes('/p/')) {
    return { pageType: PageType.Product, url: `${path}` };
  }

  if (path.includes('/q/')) {
    return { pageType: PageType.Search, url: `${path}` };
  }

  if (path.includes('/c/')) {
    return { pageType: PageType.Category, url: `${path}` };
  }

  if (path.includes('/cart')) {
    return { pageType: PageType.Cart, url: `${path}` };
  }

  if (path.includes('/checkout')) {
    return { pageType: PageType.Checkout, url: `${path}` };
  }

  if (path.includes('/confirmation')) {
    return { pageType: PageType.OrderConfirmation, url: `${path}` };
  }

  if (path.includes('/account') || path.includes('/login')) {
    return { pageType: PageType.Account, url: `${path}` };
  }

  if (path.includes('/returns')) {
    return { pageType: PageType.Returns, url: `${path}` };
  }

  if (path.includes('/profile')) {
    return { pageType: PageType.Account, url: `${path}` };
  }

  if (path.includes('/503')) {
    return { pageType: PageType.Unavailable, url: `${path}` };
  }

  if (path.includes('/404')) {
    return { pageType: PageType.NotFound, url: `${path}` };
  }

  return { pageType: PageType.LandingPage, url: `${path}` };
};
