'use client';

import { classes } from '@/next-utils/css-utils/scss-utils';
import type { FC, PropsWithChildren } from 'react';
import { MediaBreakpoint, MediaBreakpoints } from './utils/breakpoints';
import { simplifyBreakpoints } from './utils/helpers';
import {
  useIndicesOfInvisibleChildren,
  useRawBreakpoints
} from './utils/hooks';
import { SkipRenderOnClient } from './utils/SkipRenderOnClient';

import S from './styles.module.scss';

export interface IBreakpointProps extends PropsWithChildren {
  /** The breakpoint(s) for which the children should be visible. */
  media: MediaBreakpoint | MediaBreakpoints;
}

/**
 * Given a breakpoint, returns the SCSS classname which allows an element
 * to be visible under that breakpoint.
 *
 * @param breakpoint - The desired breakpoint.
 * @returns A classname.
 */
const getClassName = (breakpoint: string): string => S[breakpoint];

/**
 * Allows the developer to specify different React trees under different breakpoints.
 * Renders all trees during SSR, and allows CSS to decide which tree to show in the front end.
 * This prevents layout shifts or weird behavior during hydration phase.
 * After the initial page-load, unmounts all the trees from the DOM
 * that are not visible anyway. If the window resizes, mounts the correct trees back in.
 *
 * Must be used with the <Breakpoint> and <Default> components, both of which are no-ops
 * and are actually implemented in this component.
 *
 * The <Default> works by calculating which breakpoints are not covered by the
 * <Breakpoint>-s. Based on that, another <Breakpoint> is created which covers the
 * missing breakpoints. Since this happens during the first render, that means that the SSR
 * will render a tree that contains the <Default> tree with such CSS classes that any screen size
 * that happens to be matching the missing breakpoints, will show the <Default> tree.
 * So no client-side React logic needs to be run for that to happen.
 *
 * @example ```tsx
 * <Breakpoints>
 *  <Breakpoint media="desktop">Hello desktop users</Breakpoint>
 *  <Default>Hello everybody else!</Default>
 * </Breakpoints>
 * ```
 */
export const Breakpoints: FC<PropsWithChildren> = ({ children }) => {
  /**
   * The children are passed in as <Breakpoint> elements or <Default> element.
   *
   * Step 1. Iterate over the <Breakpoint> elements and keep track of the breakpoint coverage
   * Step 2. If there is full coverage, great, if there is no full coverage, determine the
   * gap breakpoints and make sure <Default> tree gets shown for those breakpoints
   * by giving the <Default> tree the corresponding class names.
   *
   * The hook below does all of that, it will abstract away the <Default> and <Breakpoint>
   * and just give back an array describing which elements to render
   * for which breakpoints, also ensuring full breakpoint coverage.
   *
   * If there are missing breakpoints but no <Default> tree, throws an error.
   */
  const breakpoints = useRawBreakpoints(children);

  /**
   * Read the comments in the hook itself for a proper explanation.
   * But as a small comment here, this array is empty for SSR render
   * to allow SSR to send all breakpoints and all trees.
   */
  const invisibleIndices = useIndicesOfInvisibleChildren(breakpoints);

  // Now that we have the transformed array, we can wrap every breakpoint (tree)
  // in a <span> element which gets chosen by CSS
  return breakpoints.map(({ breakpoints, tree }, index) => {
    // Best identifier I could come up with!
    // In reality the order should never change so we could use the index as well
    const key = `${breakpoints.join('_')}_${index}`;

    const isVisible = invisibleIndices.indexOf(index) === -1;

    // Transform the complex breakpoints requested by the developer
    // into an array of simple base breakpoints. The simple breakpoints
    // are then available in the SCSS module (.lg, .md, .xxs, etc)
    const classNames = simplifyBreakpoints(breakpoints).map(getClassName);

    /**
     * The line below might not seem like much, but it's quite interesting.
     * The fact that <span> is still returned and we conditionally render the tree
     * (instead of the span itself) is done on purpose. Here's why:
     *
     * Let's imagine 3 breakpoints that will end up being three <span> elements
     * during SSR. Let's also imagine they have classnames like phone, tablet, desktop.
     * So the SSR will return something like:
     * <span className="phone">phone</span>.
     * <span className="tablet">tablet</span>.
     * <span className="desktop">desktop</span>.
     *
     * Then the client-side hydration render happens, so we're essentially unmounting
     * the trees before the hydration render. If we just unmounted the span elements
     * and our component returned <span className="tablet">...</span> for example,
     * the hydration would iterate and compare the SSR html with what the component returned
     * and assume that since <span className="phone">phone</span> is the first span element
     * from SSR, and since <span className="tablet">tablet</span> is the first (and only)
     * span element returned by the hydration render, they must be the same thing.
     * Hydration doesn't care about classnames, therefore we would end up with
     * <span className="phone">tablet</span>, and that would not even be visible
     * since the user is on a tablet device and has a classname which only shows the HTML
     * for phones.
     *
     * So we must keep the <span> elements in the same exact order just empty their guts
     * so hydration also follows the lead and empties the span elements
     * instead of accidentally merging them.
     */

    return (
      <SkipRenderOnClient
        key={key}
        shouldRenderOnClient={() => isVisible}
        className={classes(S.hidden, ...classNames)}
      >
        {tree}
      </SkipRenderOnClient>
    );
  });
};

export { Breakpoint } from './Breakpoint';
export { Default } from './Default';

export * from './utils/breakpoints';
