import { SITE_BRAND } from '@/configs/env/public';
import type { ConfiguredLocaleString } from '@/constructs/LocaleSchema';
import { Site } from '@/constructs/Site';
import StaleWhileRevalidate from '@/utils/StaleWhileRevalidate';
import { InvalidArgumentError, InvalidPathError } from '@/utils/errors';
import { traversePath } from '@/utils/object-utils';
import type { LocalizationStringMap, LocalizationStringPath } from '.';
import type lang from '.';

/**
 * Describes a type that is an object of all resources strings in the entire application
 * in English. This type is used to ensure that all resource strings are defined in the
 * lang files.
 */
type StringContainer = typeof lang;

/**
 * A container for resource strings for a given locale. This class is responsible for
 * obtaining resource strings from the appropriate file, and throwing errors if the
 * resource string is not found for a specified locale.
 */
export default class ResourceStrings {
  /**
   * Instantiates a new ResourceStrings container for a given locale.
   * @param langLocale - The locale to use for this container.
   */
  public constructor(private langLocale: ConfiguredLocaleString) {}

  /**
   * A method for attempting to obtain a resource string given a path to a resource string
   * in one of our lang files. This method does not actually retrieve the resource itself
   * but rather wraps a callback method that does. This method will throw an error if the
   * resource string is not found. Sometimes this may happen because the path is invalid,
   * or because the path resolves to a object of strings, rather than a string.
   * @param msgID - The path to the resource string.
   * @param tryFunc - A callback method that attempts to retrieve the resource string.
   * @returns The resource string from the callback method.
   * @throws {@link InvalidArgumentError} If the path is invalid, or if the path resolves.
   */
  private tryGetMsg<T>(msgID: string, tryFunc: () => T): T {
    try {
      const returnValue = tryFunc();

      if (typeof returnValue === 'object') {
        throw new InvalidArgumentError(
          `The path "${msgID}" does not fully resolve to a string. It resolves to: \n` +
            JSON.stringify(returnValue, null, '  ')
        );
      }

      return returnValue;
    } catch (e) {
      if (e instanceof InvalidPathError) {
        let [file, ...path] = msgID.split('.');
        file = `@/lang/default/default/${file}.ts`;
        const pathString = path.length ? path.join('.') : '';

        throw new InvalidArgumentError(
          `Could not find resource string "${msgID}".\n` +
            (!pathString
              ? 'The path provided was invalid. The path should be in the form "fileName.path.to.resource.string".'
              : `Make sure "${file}" exists, and to check your spelling to ensure the path "${pathString}" in "${file}" is correct.`)
        );
      }

      throw e;
    }
  }

  /**
   * Attempts to find a resource string for the given message ID first with the current
   * brand and locale, then with the current brand and default locale, then with the
   * default brand and current locale, and finally with the default brand and default
   * locale. If the resource string is not found, an error is thrown.
   *
   * @param msgID - The path to the resource string.
   * @param stringContainer - The object of strings to search for the resource string in.
   * @returns The resource string for the given message ID.
   * @throws If the resource string is not found for any combination of brand and locale.
   */
  private searchForMsg(
    msgID: string,
    stringContainer: StringContainer
  ): string {
    const targetBrand = SITE_BRAND.toLowerCase() as Lowercase<Site>;
    const brand = targetBrand in stringContainer ? targetBrand : 'default';
    const locale = this.langLocale.toLowerCase();

    // Attempt to get the resource string from the file for the provided path using
    // the most specific brand and locale.
    try {
      return traversePath(
        `${brand}.${locale}.${String(msgID)}`,
        stringContainer
      );
    } catch (e) {
      // If the path is not valid, search for the string in the default locale.
      if (e instanceof InvalidPathError) {
        try {
          return traversePath(
            `${brand}.default.${String(msgID)}`,
            stringContainer
          );
        } catch (e) {
          // If the path is not valid, search for the string in the default brand and
          // the more specific locale.
          if (e instanceof InvalidPathError) {
            try {
              return traversePath(
                `default.${locale}.${String(msgID)}`,
                stringContainer
              );
            } catch (e) {
              // Finally, if the path is not valid for the string in the default brand
              // and provided locale, attempt to find the string for the default brand
              // and default locale.
              if (e instanceof InvalidPathError) {
                return traversePath(
                  `default.default.${String(msgID)}`,
                  stringContainer
                );
              }

              // If the error is an unexpected error, re-throw it.
              throw e;
            }
          }

          // If the error is an unexpected error, re-throw it.
          throw e;
        }
      }

      // If the error is an unexpected error, re-throw it.
      throw e;
    }
  }

  /**
   * A method for obtaining a resource string given a path to a resource string in one of
   * our lang files. This method will throw an error if the resource string is not found.
   * Sometimes this may happen because the path is invalid, or because the path resolves
   * to a object of strings, rather than a string.
   * @param msgID - The path to the resource string.
   * @returns The resource string.
   */
  public msg<Path extends LocalizationStringPath>(
    msgID: Path
  ): LocalizationStringMap[Path] {
      throw new Error("The `msg` method should not be called in production builds.");
  }

  /**
   * Dynamically pulls in a resource string from a file of resource strings. This method
   * will throw an error if the resource string is not found. Sometimes this may happen
   * because the path is invalid, or because the path resolves to a object of strings,
   * rather than a string. Generally, we should avoid using this method, and instead
   * statically import the resource strings we need. This method is only used when we
   * need to dynamically import a resource string, and can't reasonably reason about the
   * possible values for the message ID. Generally, a switch statement should be used to
   * determine the message ID, and the resource string should be statically imported.
   * @param msgID - The path to the resource string.
   * @returns A {@link StaleWhileRevalidate} object that will return the resource string
   * after the resource string file is loaded.
   */
  public msgDynamic(msgID: string): StaleWhileRevalidate<string> {
    /**
     * A type that represents the keys of the StringContainer object. This lists all of
     * the known resource string files.
     */
    type StringFiles = keyof StringContainer['default']['default'];

    // Uses string interpolation for the `file` variable to get Webpack to not report on
    // the use of an expression in a dynamic import.
    const strings = import(
      /* webpackMode: "lazy-once", webpackChunkName: "resource-strings" */ '.'
    ) as Promise<{
      default: StringContainer;
    }>;

    return new StaleWhileRevalidate('', async () => {
      const fileStrings = (await strings).default;

      return this.tryGetMsg(msgID, () => {
        return this.searchForMsg(msgID as StringFiles, fileStrings);
      });
    });
  }
}
