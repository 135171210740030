import type { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { ResourceNotFoundError } from '@/utils/errors';

export const { OrderNotFoundError } = errorFactory(
  'OrderNotFoundError',
  ResourceNotFoundError
);

/**
 * An error for when an order is not found during lookup.
 */
export type OrderNotFoundError = InstanceTypeOf<typeof OrderNotFoundError>;
