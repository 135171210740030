import { mocked } from '@/configs';

import { Constructor } from '@/type-utils';
import Service from '../../Service';

import MockService, { MockState, ServiceMock } from '../MockService';
import { IHistoricOrder } from './data-structures/IHistoricOrder';
import { IMaskedHistoricOrder } from './data-structures/IMaskedHistoricOrder';
import {
  GET_ORDER_MASKED,
  GET_ORDERS_MASKED,
  GET_ORDERS_UNMASKED
} from './mocks';

import type { OrderLookupService } from './OrderLookupService';
import { UnableToFindOrderError } from './errors/UnableToFindOrderError';

const initialState = {};

/**
 * Mock implementation of the OrderLookupService class.
 */
class OrderLookupServiceMock extends ServiceMock<OrderLookupService> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): OrderLookupService {
    return MockService.getMockOf(this.service) as unknown as OrderLookupService;
  }

  /**
   * The constructor to initialize the mocks.
   * @param service - The service that is being mocked.
   */
  public constructor(private service: Constructor<OrderLookupService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(service: Service): void {
    const mockEnabled: boolean = mocked.OrderLookupService;

    MockService.mockService(
      mockEnabled,
      service,
      {
        getOrders: (
          pageSize: number,
          pageNumber: number,
          zipcode: string,
          orderIDOrEmailAddress: string
        ) => {
          if (orderIDOrEmailAddress === 'SUUS3331RK0SXV') {
            throw new UnableToFindOrderError(
              'Unable to find an order for order number, email, or zip codes do not match.'
            );
          }

          if (zipcode && orderIDOrEmailAddress) {
            return Promise.resolve({
              orders: {
                ...GET_ORDERS_MASKED,
                allItems: this.paginateOrdersItems(
                  GET_ORDERS_MASKED.allItems,
                  pageSize,
                  pageNumber
                )
              }
            });
          }

          if (zipcode || orderIDOrEmailAddress) {
            return Promise.resolve(null);
          }

          return Promise.resolve({
            orders: {
              ...GET_ORDERS_UNMASKED,
              allItems: this.paginateOrdersItems(
                GET_ORDERS_UNMASKED.allItems,
                pageSize,
                pageNumber
              )
            }
          });
        },
        getOrder: (orderID: string, orderToken: string) => {
          // Use this order id (SUUSC6858ST18Q) to get the masked order.
          if (orderID === 'SUUSC6858ST18Q' && orderToken) {
            return Promise.resolve(GET_ORDER_MASKED);
          }

          throw new UnableToFindOrderError(
            'Unable to find an order for order number, email, or zip codes do not match.'
          );
        },
        isOrderReturnable: () => true
      },
      {},
      this.state
    );
  }

  /**
   * `paginateOrdersItems` takes a array of order items and paginates it based on `pageSize` and `pageNumber`.
   * @param items - The order items, it can be Masked or Unmasked orders.
   * @param pageSize - The page size.
   * @param pageNumber - The current page number.
   * @returns - Paginated order items.
   */
  private paginateOrdersItems(
    items: Array<IMaskedHistoricOrder> | Array<IHistoricOrder>,
    pageSize: number,
    pageNumber: number
  ): Array<IMaskedHistoricOrder> | Array<IHistoricOrder> {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    return items.slice(startIndex, endIndex);
  }
}

export default OrderLookupServiceMock;
